.start-point-map {
  .modal-body {
    overflow-y: auto;
    height: 100%;

    @media (min-width: $ss-breakpoint-md) {
      height: 465px;
      overflow-y: visible;
    }
  }

  img {
    background-color: transparent;
  }
}

.start-point-map__detail {
  padding-top: 64px;

  @media (min-width: $ss-breakpoint-md) {
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    overflow-y: auto;
    height: 100%;
  }
}

.accommodation-map__box,
.start-point-map__box {
  position: absolute;
  z-index: $ss-zindex-30;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 79%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 8px;
  background: $white;
  border: 2px solid theme('colors.brand.green');
  border-radius: theme('borderRadius.4xl');

  @media (min-width: $ss-breakpoint-md) {
    left: 17px;
    transform: none;
    top: auto;
    bottom: 23px;
  }
}

.start-point-map__row {
  @media (min-width: $ss-breakpoint-md) {
    height: 100%;
  }
}

.accommodation-map__container {
  height: 320px;

  @media (min-width: $ss-breakpoint-md) {
    height: 480px;
  }
}

.accommodation-map__box {
  @media (min-width: $ss-breakpoint-md) {
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
  }
}

.start-point-map__block {
  position: relative;
  border: 2px solid theme('colors.brand.green');
  border-radius: theme('borderRadius.lg');
  height: 260px;

  @media (min-width: $ss-breakpoint-md) {
    height: 400px;
  }

  .start-point-map__box {
    top: auto;
    bottom: 20px;

    @media (min-width: $ss-breakpoint-md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
