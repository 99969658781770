@mixin right-stick-arrow() {
  .slick-next,
  .slick-prev {
    width: 32px;
    height: 32px;
    line-height: 32px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .slick-prev {
    left: auto;
    right: 64px;
  }

  .slick-next {
    right: 16px;
  }

  .slick-arrow {
    top: -60px;
    transform: translateY(0);
  }
}

.carousel-sao-images {
  position: relative;
  margin: 0 -15px;

  @media (min-width: $ss-breakpoint-md) {
    margin: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-arrow {
    width: 50%;
    height: 100%;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .slick-slide {
    cursor: pointer;
  }

  .slick-dots {
    bottom: -28px;

    li + li {
      margin-left: 6px;
    }
  }

  .slick-dots li {
    border-color: $ss-grey-color;
    cursor: pointer;
    padding: 3px;

    &.slick-active {
      background-color: $ss-grey-color;
    }
  }

  &__item-img {
    position: relative;
    width: 100%;
    height: 280px;

    @media (min-width: 375px) {
      height: 340px;
    }

    @media (min-width: $ss-breakpoint-md) {
      height: 380px;
    }
  }

  &__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $white;
    width: 100%;
    padding: 0 15px;
    height: 70px;
    width: 100%;
    padding: 12px 15px 0;
    background: linear-gradient(0deg, rgba($body-color, 0.6) 40%, rgba($body-color, 0.15) 100%);
  }
}

.slider-v2.carousel-itineraries {
  .slick-next {
    &:before {
      top: 3px;
      border-color: theme('colors.brand.navy');
    }
  }
}

.slider-v2.carousel-city-highlights {
  .slick-next {
    &:before {
      top: 3px;
      border-color: theme('colors.brand.navy');
    }
  }

  .slick-track {
    margin: auto;
  }
}

@include fix-slick-item-height('carousel-sao-images__item');

.carousel--rectangle-dots {
  .slick-dots {
    bottom: -32px;

    li {
      border: 0;
      cursor: pointer;
      border-radius: 0;
      background-color: $ss-grey-light-color;
      padding: 3px 10px;
      transition: background-color 0.2s ease-in-out;

      &.slick-active {
        background-color: $body-color;
      }
    }
  }

  &.carousel-country-magazine {
    .slick-dots {
      bottom: 0;
    }
  }
}

.carousel-blog {
  position: relative;

  &__item {
    position: relative;
    height: 480px;

    @media (min-width: $ss-breakpoint-md) {
      height: 600px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__item-body {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 15px;
  }

  &__item-body-inner {
    max-width: 600px;
  }

  &__item-text {
    text-transform: uppercase;
    color: $white;
    font-size: $ss-font-size-lg;
    font-weight: $ss-font-weight-bold;
    line-height: 1;
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
    }
  }

  &__item-title {
    font-size: $ss-font-size-title-4;
    color: $white;
    line-height: 1.2;
    font-weight: $ss-font-weight-bold;
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-6;
      margin-bottom: 24px;
    }
  }

  &__item-btn {
    display: inline-block;
    color: $ss-black-color;
    background-color: $white;
    border: 1px solid $white;
    padding: 8px 32px;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $ss-black-color;
      font-weight: $ss-font-weight-bold;
    }
  }

  .slick-prev {
    left: 182px;
  }

  .slick-next {
    right: 182px;
  }
}

@include fix-slick-item-height('carousel-blog__item');

.carousel-accommodation-room,
.carousel-activity-sao-image {
  &__btn-inner {
    width: 28px;
    height: 28px;
    line-height: 24px;
    background-color: theme('colors.grey.lighter');
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 5px 6px rgba(0,0,0,0.2);
    text-align: center;
  }

  .carousel-control-prev,
  .carousel-control-next,
  &__btn-prev,
  &__btn-next {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: $ss-zindex-80;
    cursor: pointer;
    width: 60px;
    padding: 16px 0;

    @media (min-width: $ss-breakpoint-md) {
      visibility: hidden;
    }

    svg {
      width: 20px;
      height: 20px;
      color: $ss-black-color;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 28px;
    padding: 0;
    opacity: 1;
  }

  &:hover .carousel-control-prev,
  &:hover .carousel-control-next {
    visibility: visible;
    opacity: 1;
  }

  .carousel-control-prev,
  &__btn-prev {
    left: 8px;
    text-align: left;
  }

  .carousel-control-next,
  &__btn-next {
    right: 8px;
    text-align: right;
  }

  &:hover &__btn-prev,
  &:hover &__btn-next {
    visibility: visible;
  }

  .carousel-indicators {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
    padding: 0;
    margin: 0;
    text-align: center;
    display: block;

    li {
      font-size: 0;
      display: inline-block;
      border: 0;
      border-radius: 50%;
      background-color: theme('colors.grey.lighter');
      padding: 0;
      transition: all 0.2s ease-in-out;
      width: 8px;
      height: 8px;
      margin-right: 0;

      &.active {
        background-color: theme('colors.purple.DEFAULT');
      }
    }

    li + li {
      margin-left: 5px;
    }
  }

  .carousel-item {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 260px;
  }

  &__item {
    img {
      object-fit: cover;
      width: 100%;
      height: 260px;
      border-radius: $ss-border-radius-lg;

      @media (min-width: $ss-breakpoint-md) {
        height: 260px;
      }
    }
  }

  .slick-prev {
    left: -12px;
  }

  .slick-next {
    right: -12px;
  }

  .slick-prev,
  .slick-next {
    width: 24px;
    height: 24px;
    line-height: 24px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .slick-arrow {
    visibility: hidden;
  }

  .slick-dots li {
    padding: 3px;
  }

  &:hover {
    .slick-arrow {
      visibility: visible;
    }
  }
}

.carousel-accommodation-room {
  .carousel-item {
    height: 320px;
  }
}

.carousel-activity-sao-image--corp {
  .carousel-activity-sao-image__btn-prev {
    left: 0;
    text-align: center;
  }

  .carousel-activity-sao-image__btn-next {
    right: 0;
    text-align: center;
  }

  .carousel-indicators {
    bottom: 6px;

    li {
      width: 4px;
      height: 4px;
    }
  }

  .carousel-item {
    img {
      object-fit: cover;
      width: 100%;
      height: 248px;
      border-radius: $ss-border-radius-lg;

      @media (min-width: $ss-breakpoint-md) {
        height: 248px;
      }
    }
  }
}

@include fix-slick-item-height('carousel-activity-sao-image__item');

.option-comparison__carousel {
  margin-bottom: 16px;

  &-item {
    img {
      object-fit: cover;
      width: 100%;
      height: 120px;
      border-radius: $ss-border-radius-sm;

      @media (min-width: $ss-breakpoint-md) {
        height: 320px;
      }
    }
  }

  &-icon-prev,
  &-icon-next {
    position: absolute;
    z-index: $ss-zindex-100;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background-color: $white;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 5px 6px rgba(0, 0, 0, 0.2);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &-icon-prev {
    left: -12px;
  }

  &-icon-next {
    right: -12px;
  }
}

@include fix-slick-item-height('option-comparison__carousel-item');

.carousel-tried-and-tested {
  margin-left: -16px;
  margin-right: -16px;

  &__item {
    padding-left: 4px;
    padding-right: 4px;
    position: relative;

    @media (min-width: $ss-breakpoint-md) {
      padding-left: 16px;
      padding-right: 16px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 170px;

      @media (min-width: $ss-breakpoint-md) {
        height: 195;
      }
    }
  }
}

@include fix-slick-item-height('carousel-tried-and-tested__item');

.carousel-marker-popup {
  &__item {
    img {
      background-color: $ss-grey-lighter-color;
      object-fit: cover;
      width: 100%;
      height: 150px;
    }
  }

  .slick-prev {
    left: 8px;
  }

  .slick-next {
    right: 8px;
  }

  .slick-prev,
  .slick-next {
    width: 24px;
    height: 24px;
    line-height: 24px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@include fix-slick-item-height('carousel-marker-popup__item');

.hero-country__carousel {
  padding-top: 0;
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: $ss-breakpoint-md) {
    padding-left: 0;
    width: 800px;
  }

  .slick-slide {
    @media (min-width: $ss-breakpoint-md) {
      padding: 0 8px;
    }
  }

  &-item {
    cursor: pointer;
    position: relative;
    padding: 0 16px;
    text-align: center;
    width: calc(100% / 3);
    flex-shrink: 0;
    margin-bottom: 12px;

    @media (min-width: $ss-breakpoint-md) {
      width: auto;
      padding: 0;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &-img {
    margin: 0 auto;
    margin-bottom: 4px;
    position: relative;
    width: 80px;
    height: 80px;

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 0;
      width: 100%;
      height: 204px;
    }
  }

  &-overlay {
    @media (min-width: $ss-breakpoint-md) {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 16px;
      background: linear-gradient(0deg, rgba($body-color, 0.6) 40%, rgba($body-color, 0.15) 100%);
      height: 80px;
      border-bottom-left-radius: $ss-border-radius-base;
      border-bottom-right-radius: $ss-border-radius-base;
    }
  }

  &-title {
    color: $white;
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-xs;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
    }
  }

  .slick-prev {
    left: 5px;

    @media (min-width: $ss-breakpoint-md) {
      left: 8px;
      right: auto;
    }

    &.slick-disabled {
      display: block !important;
      opacity: 0.5;
    }
  }

  .slick-next {
    left: 49px;

    @media (min-width: $ss-breakpoint-md) {
      left: 64px;
      right: auto;
    }

    &.slick-disabled {
      display: block !important;
      opacity: 0.5;
    }
  }

  .slick-prev,
  .slick-next {
    top: auto;
    bottom: -75px;
    background-color: transparent;
    box-shadow: none;

    @media (min-width: $ss-breakpoint-md) {
      border: 1px solid $white;
    }

    &:hover {
      svg {
        color: $white;
      }
    }

    svg {
      color: $white;
    }
  }

  .slick-dots {
    bottom: -28px;
  }

  .slick-dots li {
    padding: 4px;
  }

  .slick-dots li.slick-active {
    background-color: $ss-cyan-color;
  }
}

@include fix-slick-item-height('hero-country__carousel-item');

.carousel-country-collection {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  flex-wrap: wrap;

  .slick-list {
    width: 100%;
  }

  @media (min-width: $ss-breakpoint-md) {
    margin-left: -15px;
    margin-right: -15px;
    display: block;
  }

  &__item {
    padding-left: 8px;
    padding-right: 8px;
    width: 50%;
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      display: none;
      margin-bottom: 0px;

      &:first-child {
        display: block;
      }

      .slick-initialized j {
        display: block;
      }
    }
  }

  @include right-stick-arrow();

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    display: block !important;
    opacity: 0.5;
    cursor: default;

    &:hover {
      svg {
        color: $ss-black-color;
      }
    }
  }
}

.carousel-country-magazine {
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: $ss-breakpoint-md) {
    margin-left: 0;
    margin-right: 0;
  }

  &__item.slick-slide {
    display: block;

    @media (min-width: $ss-breakpoint-md) {
      display: flex;
      align-items: center;
      width: 50%;
    }
  }

  &__item-panel {
    width: 100%;

    @media (min-width: $ss-breakpoint-md) {
      width: 50%;
    }
  }

  &__img {
    height: 320px;
  }

  @include right-stick-arrow();

  .slick-arrow {
    top: -2px;
  }
}

.carousel-counter {
  display: none;
  position: absolute;
  top: 0;
  right: 96px;

  @media (min-width: $ss-breakpoint-md) {
    display: block;
  }

  &--magazine {
    top: 8px;
    right: 110px;
  }

  &--collection  {
    top: -52px;
  }

  &--other-options {
    top: -38px;
  }

  &--category {
    top: -56px;
  }
}

.carousel-other-options {
  margin-left: -15px;
  margin-right: -15px;

  > {
    @include right-stick-arrow();

    .slick-arrow {
      top: -48px;
    }
  }

  &.slick-slider .slick-track {
    min-width: 100%;
  }
}

.carousel-collection-options {
  margin-left: -8px;
  margin-right: -8px;

  @media (min-width: $ss-breakpoint-md) {
    margin-left: -15px;
    margin-right: -15px;
  }

  > {
    @include right-stick-arrow();
  }

  &.slick-slider .slick-track {
    min-width: 100%;
    overflow-y: hidden;
  }

  &--grid {
    &.slick-slider .slick-track {
      max-height: 453px;
    }
  }

  &--simple {
    &.slick-slider .slick-track {
      max-height: 396px;
    }
  }

  &--list {
    padding-left: 15px;
    padding-right: 15px;

    &.slick-slider .slick-track {
      max-height: 900px;
      padding-top: 1px;
    }
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    display: block !important;
    opacity: 0.5;
    cursor: default;

    &:hover {
      svg {
        color: $ss-black-color;
      }
    }
  }

  &--category {
    > .slick-arrow {
      top: -72px;
    }
  }

  &__item {
    &:focus {
      outline: none !important;
    }
  }
}

@include fix-slick-item-height('carousel-collection-options__item');

.carousel-video {

  &__item {
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $ss-true-black-color;
      top: 0;
      left: 0;

      @media (min-width: $ss-breakpoint-md) {
        height: 400px;
        position: static;
      }
    }
  }

  &__item-inner {
    position: relative;
    padding: 177% 0 0 0;

    @media (min-width: $ss-breakpoint-md) {
      padding: 0;
    }
  }
}

.see-more-collection {
  padding-right: 0;
  font-size: $ss-font-size-xs;

  @media (min-width: $ss-breakpoint-md) {
    padding-right: 100px;
    font-size: $ss-font-size-base;
  }
}

.carousel-gift-card {
  .slick-slide {
    &:focus,
    &:active {
      outline: none;
    }
  }

  .slick-arrow {
    top: -28px;
    color: theme('colors.brand.navy');
  }

  .slick-prev {
    left: auto;
    right: 60px;
  }

  .slick-next {
    right: 12px;

    &:before {
      content: ' ';
      position: absolute;
      left: -7px;
      top: 1px;
      display: inline-block;
      height: 32px;
      border-left: 2px solid theme('colors.brand.navy');
      transform: rotate(35deg);
    }
  }

  .slick-next,
  .slick-prev {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: transparent;

    &.slick-disabled {
      opacity: 0.6;
      cursor: default;
      display: block !important;
    }
  }
}

.carousel-why-gift {
  .slick-dots {
    bottom: -24px;
  }

  .slick-dots li.slick-active {
    background-color: $ss-black-color;
  }

  .slick-dots li {
    padding: 4.5px;
    border-color: $ss-black-color
  }
}

.carousel-our-pick-option {
  .slick-prev {
    top: -26px;
    right: 40px;
    left: auto;
  }

  .slick-next {
    top: -26px;
    right: 0;
    left: auto;
  }

  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: none !important;

    @media (min-width: theme('screens.md')) {
      display: block !important;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .slick-slide.slick-current {
    z-index: theme('zIndex.10');
  }

  .slick-dots {
    bottom: -24px;
  }

  .slick-dots li.slick-active {
    background-color: $ss-black-color;
  }

  .slick-dots li {
    padding: 4.5px;
    border-color: $ss-black-color
  }
}

.carousel-collection-activity-review {
  .slick-arrow {
    top: -28px;
  }

  .slick-prev {
    left: auto;
    right: 32px;
  }

  .slick-next {
    right: -4px;
  }

  .slick-next,
  .slick-prev {
    width: 24px;
    height: 24px;
    line-height: 24px;

    svg {
      width: 16px;
      height: 16px;
    }

    &.slick-disabled {
      opacity: 0.6;
      cursor: default;
      display: block !important;
    }
  }
}

.carousel-homework {
  .slick-dots {
    bottom: -32px;
  }
}

.carousel-top-collection {
  .slick-dots {
    bottom: -10px;
    @media (min-width: theme('screens.md')) {
      bottom: -32px;
    }
  }
}

.slider-v2.carousel-field-notes {
  .slick-arrow {
    top: -80px;
    bottom: auto;
    transform: none;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .slick-prev {
    left: auto;
    right: 77px;
  }

  .slick-next {
    left: auto;
    right: 28px;

    &:before {
      top: 3px;
      border-color: theme('colors.brand.navy');
    }
  }
}

.slider-v2.carousel-featured-blog {
  .slick-list,
  .slick-track {
    padding-top: 20px;
  }

  .slick-arrow {
    bottom: -40px;

    @media (min-width: theme('screens.md')) {
      bottom: 48px;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .slick-prev {
    left: 50%;

    @media (min-width: theme('screens.md')) {
      left: 25%;
    }
  }

  .slick-next {
    left: 50%;

    @media (min-width: theme('screens.md')) {
      left: 25%;
    }

    &:before {
      border-left: 2px solid theme('colors.brand.navy');
    }
  }
}

.slider-v2.carousel-featured-guide {
  .slick-dots {
    bottom: -36px;
  }

  .slick-arrow {
    left: auto;
    right: 0;
    top: -114px;
    bottom: auto;
  }

  .slick-prev {
    right: 56px;
  }

  .slick-next {
    right: 16px;
  }

  .slick-next {
    &:before {
      border-left: 2px solid theme('colors.brand.navy');
    }
  }
}
