.minimal-option {
  .navbar.navbar-primary {
    top: 0;
    border-bottom: 1px solid $ss-border-color;
  }

  #content {
    padding-top: 66px;
  }
}
