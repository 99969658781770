.sao-highlight {
  width: 140px;
  padding-bottom: 12px;

  @media (min-width: $ss-breakpoint-md) {
    padding-bottom: 0;
  }

  &__img {
    @include img-as-bg();
    width: 100%;
    height: 105px;
    margin-bottom: 8px;
    border-radius: $ss-border-radius-sm;
  }

  &__title {
    text-align: center;
  }

  &__icon {
    svg {
      fill: $ss-grey-color;
      stroke: $white;
    }
  }
}

.accom-datepicker-date-info {
  display: block;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: $ss-breakpoint-md) {
    display: flex;
  }
}

.nav-pills.nav-pills--accommodation {
  display: none;

  @media (min-width: $ss-breakpoint-md) {
    display: flex;
  }

  .nav-link {
    border-radius: $ss-border-radius-base;
    background-color: $white;
    border: 1px solid $ss-grey-light-color;
    color: $ss-grey-light-color;
    font-size: $ss-font-size-sm;
    min-width: 94px;
    text-align: center;

    &.active {
      border-color: $ss-cyan-color;
      color: $ss-cyan-color;
    }
  }

  .nav-item + .nav-item {
    margin-left: 16px;
  }
}

.list-accommodation {
  display: flex;

  &__item + &__item {
    margin-left: -1px;
  }

  &__title {
    font-size: $ss-font-size-xs;
    font-weight: $ss-font-weight-bold;
    margin-bottom: 4px;
    text-align: center;
    color: $ss-grey-light-color;

    svg {
      color: $ss-grey-light-color;
    }
  }

  &__item {
    padding: 8px;
    flex-grow: 0;
    width: calc(100% / 3);
    background-color: $white;
    border: 1px solid $ss-border-color;

    &:first-child {
      border-top-left-radius: $ss-border-radius-base;
      border-bottom-left-radius: $ss-border-radius-base;
    }

    &:last-child {
      border-top-right-radius: $ss-border-radius-base;
      border-bottom-right-radius: $ss-border-radius-base;
    }
  }

  &__item.active &__title,
  &__item.active &__title svg {
    color: $ss-cyan-color;
  }

  &__checkbox {
    text-align: center;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 80px;
    margin-bottom: 6px;
    border-radius: $ss-border-radius-base;
    opacity: 0.7;
  }

  &__item.active &__img {
    opacity: 1;
  }
}

.modal-footer__datepicker {
  width: 100%;

  @media (min-width: $ss-breakpoint-md) {
    width: auto;
  }
}

.popup-slide {
  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
  z-index: 1201;
  transform: translateY(100%);
  transition: transform 0.1s ease-in-out;

  &.active {
    transform: translateY(0);
    background-color: rgba($ss-black-color, 0.5);
  }

  &__inner {
    position: relative;
    height: 100%;
  }

  &__content {
    height: 70%;
    background-color: $white;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: $ss-font-weight-bold;
    padding: 12px;
  }

  &__body {
    max-height: calc(100% - 40px);
    overflow-y: auto;
  }
}

.carousel-option-media {
  margin-bottom: 32px;

  @media (min-width: $ss-breakpoint-md) {
    margin-bottom: 0;
  }

  .slick-dots {
    bottom: -28px;

    li + li {
      margin-left: 6px;
    }
  }

  .slick-dots li {
    border-color: $ss-grey-color;
    cursor: pointer;
    padding: 3px;

    &.slick-active {
      background-color: $ss-grey-color;
    }
  }
}
