.popup-action {
  background: $white;
  width: 180px;
  position: absolute;
  z-index: $ss-zindex-100;
  top: 49px;
  right: -20px;
  left: auto;
  transform: none;
  padding: 12px 0;
  border: 1px solid $ss-border-color;
  border-radius: $ss-border-radius-base;
  display: none;

  &:before {
    @include triangle('top', $ss-border-color, 10px);
    position: absolute;
    right: 27px;
    left: auto;
    transform: none;
    top: -15px;
  }

  &:after {
    @include triangle('top', $white, 9px);
    position: absolute;
    right: 28px;
    left: auto;
    transform: none;
    top: -14px;
  }

  @media (min-width: $ss-breakpoint-lg) {
    top: 53px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: -15px;
    }

    &:after {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: -14px;
    }
  }

  &.popup-action--edge-right {
    left: auto;
    transform: none;
    right: 0;

    &:before {
      right: 7px;
      left: auto;
      top: -15px;
      transform: none;
    }

    &:after {
      right: 8px;
      left: auto;
      top: -13px;
      transform: none;

      @media (min-width: $ss-breakpoint-lg) {
        top: -14px;
      }
    }
  }

  &.active {
    display: block;
  }

  a {
    padding: 4px 16px;
    display: inline-block;

    &:hover {
      background-color: $ss-grey-lightest-color;
    }
  }
}
