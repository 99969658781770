.notice-box {
  padding: 24px;
  background-color: $ss-cyan-lighter-color;
  border-top: 4px solid $ss-yellow-color;
  border-left: 0;
  border-radius: $ss-border-radius-base;

  @media (min-width: $ss-breakpoint-md) {
    border-top: 0;
    border-left: 4px solid $ss-yellow-color;
    border-radius: 0;
  }

  &--grey {
    background-color: $ss-grey-lightest-color;
  }

  &--red {
    background-color: $white;
    border-top-color: $ss-red-color;

    @media (min-width: $ss-breakpoint-md) {
      border-left: 4px solid $ss-red-color;
    }
  }
}
