input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

label + .form-text {
  margin-bottom: 8px;
  font-size: $ss-font-size-sm;
  color: $ss-grey-color;
}

.custom-select,
.form-control {
  color: theme('colors.brand.navy');
  border: 1px solid theme('colors.grey.lighter');
  border-radius: theme('borderRadius.lg');
  height: 40px;
  line-height: 18px;
  padding: 10px 12px;

  &::placeholder {
    color: theme('colors.grey.DEFAULT');
  }

  &::-ms-input-placeholder { /* Edge 12 -18 */
    color: theme('colors.grey.DEFAULT');
  }

  &:focus {
    border-color: theme('colors.grey.lighter');
    outline: none;
    box-shadow: none;
  }
}

.input-group-text {
  background-color: $white;
  border-radius: theme('borderRadius.lg');
  height: 40px;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-left: 0;
}

.input-group {
  &.is-invalid {
    .input-group-text,
    .form-control {
      border-color: $ss-red-color;
    }

    .invalid-feedback {
      display: block;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }
}

.form-check {
  padding-left: 0;
}

.form-check-label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;

  .form-check-label-desc {
    display: inline-block;
    padding-top: 8px;
    color: theme('colors.brand.navy');
    padding-left: 30px;
    white-space: initial;

    &--sm {
      font-size: $ss-font-size-sm;
    }
  }

  &:before {
    content: '';
    -webkit-appearance: none;
    background-color: $white;
    border: 1px solid theme('colors.brand.navy');
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.form-check-input:checked + .form-check-label:before {
  background-color: theme('colors.orange.DEFAULT');
  border-color: theme('colors.orange.DEFAULT');
}

.form-check-input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  &[type="checkbox"] {
    + .form-check-label:before {
      border-radius: $ss-border-radius-sm;
    }

    +.form-check-sm:before {
      transform: scale(0.8);
    }

    &:checked + .form-check-label:after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 7px;
      width: 5px;
      height: 11px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:indeterminate + .form-check-label:before {
      background-color: theme('colors.orange.DEFAULT');
      border-color: theme('colors.orange.DEFAULT');
    }

    &:indeterminate + .form-check-label:after {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      left: 2px;
      width: 14px;
      border: solid $white;
      border-width: 0 2px 2px 0;
    }
  }

  &[type="radio"] {
    + .form-check-label:before {
      border-radius: 50%;
    }
  }
}

.input-modal-upload {
  width: 100%;

  @media (min-width: $ss-breakpoint-lg) {
    width: 310px;
  }
}
