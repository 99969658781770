.popover {
  background-color: theme('colors.brand.yellow');
  color: theme('colors.brand.navy');
  border-radius: theme('borderRadius.lg');
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.popover-body {
  color: theme('colors.brand.navy');
}

.popover-no-arrow .arrow {
  display: none !important;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: theme('colors.brand.yellow');
}
