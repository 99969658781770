.lightbox {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: $ss-zindex-1500;
	background-color: rgba(#000, 0.9);
	height: 100%;

  &__inner {
    position: relative;
    height: 100%;
  }

  &__nav,
  &__main {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
  }

  &__nav {
    margin-top: 15px;
    padding: 0 15px;
  }

	&__main {
    padding: 80px 15px 0;
    height: calc(100% - 90px);

    @media (min-width: $ss-breakpoint-md) {
      height: initial;
    }

    img {
      width: 100%;
      height: auto;
    }
	}

  &__main-carousel {
    height: 100%;

    @media (min-width: $ss-breakpoint-md) {
      height: auto;
    }

    .slick-list,
    .slick-track,
    .slick-slide > div,
    .slick-slider {
      height: 100%;
    }

    .slick-slide.slick-active {
      z-index: $ss-zindex-1000;
    }
  }

  &__main-carousel-item {
    height: 100%;

    @media (min-width: $ss-breakpoint-md) {
      height: auto;
    }
  }

  &__main-carousel-item-img {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    iframe {
      width: 100%;
      height: 100%;
    }

    @media (min-width: $ss-breakpoint-md) {
      display: block;
      height: 384px;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__main-carousel-item-inner {
    height: 100%;
  }

  &__nav-carousel {
    display: none;

    @media (min-width: $ss-breakpoint-sm) {
      display: block;
    }
  }

  &__nav-carousel-item {
    padding: 0 4px;
  }

  &__nav-carousel-item-img {
    height: 94px;
    position: relative;
    border: 2px solid transparent;
    border-radius: theme('borderRadius.lg');

    iframe {
      width: 100%;
      height: 90px;
      display: block;
      border-radius: theme('borderRadius.lg');
    }

    img {
      border-radius: theme('borderRadius.lg');
    }

    .slick-current & {
      border: 2px solid theme('colors.brand.green');
      border-radius: theme('borderRadius.lg');
    }
  }

  &__btn-next,
  &__btn-prev,
  &__btn-close {
    position: absolute;
    display: block;
    cursor: pointer;
    color: $white;
  }

  &__btn-next,
  &__btn-prev {
    &.slick-arrow {
      width: 28px;
      height: 28px;
      line-height: 24px;
      top: 50%;
      transform: translateY(-50%);
      background-color: theme('colors.grey.lighter');
      color: theme('colors.brand.navy');
      border-radius: 50%;
      text-align: center;

      svg {
        stroke-width: 2px;
        width: 20px;
        height: 20px;
      }

      @media (min-width: $ss-breakpoint-md) {
        width: 48px;
        height: 48px;
        line-height: 48px;
        background-color: transparent;
        color: $white;
        border-radius: none;

        svg {
          width: 48px;
          height: 48px;
        }
      }
    }
  }


  &__btn-prev {
    left: 20px;

    @media (min-width: $ss-breakpoint-md) {
      left: 30px;
    }
  }

  &__btn-next {
    right: 20px;

    @media (min-width: $ss-breakpoint-md) {
      right: 30px;
    }
  }

  &__btn-close {
    top: 15px;
    right: 5px;
    width: 48px;
    height: 48px;
    line-height: 48px;

    @media (min-width: $ss-breakpoint-md) {
      top: 30px;
      right: 30px;
    }
  }
}

.lightbox-video-container {
  position: relative;
  width: 100%;
  height: 100%;
}
