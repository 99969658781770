.ribbon {
  position: absolute;
  width: 128px;
  height: 24px;
  line-height: 26px;
  padding-left: 11px;
  left: -10px;
  top: -11px;
  font-size: $ss-font-size-sm;
  font-weight: $ss-font-weight-bold;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 1px 2px 1px rgba(0,0,0,0.32);
  display: flex;
  align-items: center;

  &:before {
    content: ' ';
    position: absolute;
    height: 0;
    width: 0;
    bottom: -10px;
    left: 0;
    border-top: 10px solid $ss-cyan-dark-color;
    border-left: 10px solid transparent;
  }

  @media (min-width: 375px) {
    width: 158px;
  }

  @media (min-width: $ss-breakpoint-md) {
    width: 140px;
    height: 30px;
    line-height: 30px;
    left: -26px;
    top: -14px;
    font-size: $ss-font-size-sm;
    text-align: center;
  }

  &--primary {
    background: $ss-purple-color;
    color: $white;

    &:before {
      border-top-color: darken($ss-purple-color, 10%);
    }
  }

  &__inner {
    position: relative;
  }
}

.ribbon-star {
  position: absolute;
  top: -6px;
  right: 4px;
  text-align: center;
  border-top-left-radius: 3px;
  background: $ss-yellow-color;
  padding: 2px 0;
  width: 20px;

  @media (min-width: $ss-breakpoint-md) {
    right: 12px;
  }

  &--static {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;

    &:before {
      display: none;
    }

    &:after {
      height: 0;
      width: 0;
      bottom: -4px;
      right: 0;
      border-left: 8px solid $ss-yellow-color;
      border-right: 8px solid $ss-yellow-color;
      border-bottom: 4px solid transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    height: 0;
    width: 0;
    left: -5px;
    top: 0;
    border-bottom: 6px solid darken($ss-yellow-color, 10%);
    border-left: 6px solid transparent;
  }

  &:after {
    height: 0;
    width: 0;
    bottom: -4px;
    right: 0;
    border-left: 10px solid $ss-yellow-color;
    border-right: 10px solid $ss-yellow-color;
    border-bottom: 4px solid transparent;
  }
}

.ribbon-rec {
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  font-size: $ss-font-size-sm;
  line-height: 1;
  font-weight: $ss-font-weight-bold;
  text-align: center;
  border-radius: 4px;
  box-shadow: 1px 2px 1px rgba(0,0,0,0.32);
  padding: 4px 0;

  @media (min-width: $ss-breakpoint-md) {
    font-size: $ss-font-size-base;
  }

  &--primary {
    color: $white;
    background-color: $ss-cyan-color;
  }

  &--secondary {
    color: $white;
    background-color: $ss-purple-color;
  }
}

.ribbon-shield {
  position: absolute;
  z-index: $ss-zindex-30;
  top: 12px;
  left: 27px;
  line-height: 1;
  border-radius: theme('borderRadius.2xl');
  font-size: theme('fontSize.sm');
  font-weight: $ss-font-weight-bold;
  color: theme('colors.white');
  font-family: theme('fontFamily.styleSans');
  background-color: theme('colors.orange.DEFAULT');
  padding: 6px 10px;
  text-transform: lowercase;

  @media (min-width: theme('screens.md')) {
    padding: 5px 10px;
    font-size: theme('fontSize.base');
  }

  &--yellow {
    background-color: $ss-yellow-color;
    color: $white;
  }

  &__img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
}
