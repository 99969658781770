@mixin img-as-bg() {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $ss-grey-lightest-color;
}

@mixin overlay() {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $ss-zindex-10;
  transition: background-color .3s ease-in-out;
}

@mixin hero-overlay() {
  &:after {
    content: ' ';
    position: absolute;
    background-color: rgba($black, 0.2);
    width: 100%;
    height: 100%;
  }
}

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}

@mixin fix-slick-item-height($selector) {
  .#{$selector} {
    display: none;
  }

  .#{$selector}:first-child {
    display: block;
  }

  .slick-initialized .#{$selector} {
    display: block;
  }
}

@mixin img-blurhash() {
  width: 0;
  height: 0;
  visibility: hidden;

  &.lazyloaded {
    visibility: visible;
  }
}
