.circle {
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: $ss-font-size-xs;
  text-align: center;

  &--red {
    background: $ss-red-color;
    color: $white;
  }

  &--white {
    background: $white;
    border-color: $ss-grey-light-color;
  }

  &--cyan {
    background: $ss-cyan-color;
    border-color: $ss-grey-light-color;
  }

  &--yellow {
    background: $ss-yellow-color;
  }

  &--purple {
    background: $ss-purple-color;
    border-color: $ss-grey-light-color;
  }

  &--black {
    background: $ss-black-color;
  }

  &--whatsapp {
    background: $whatsapp-color;
  }

  &--outline-blue {
    border-color: $ss-cyan-color;
    background: $white;
  }

  &--outline-black {
    border-color: $black;
    background: $white;
  }

  &--outline-navy {
    border-color: theme('colors.brand.navy');
    background: $white;
  }

  &--gray {
    background: $ss-grey-light-color;
    color: $white;

    &.active {
      background: $ss-cyan-color;
    }
  }

  &--teal {
    background: $ss-teal-color;
  }

  &--4 {
    width: 4px;
    height: 4px;
  }

  &--8 {
    width: 8px;
    height: 8px;
  }

  &--10 {
    width: 10px;
    height: 10px;
  }

  &--sm {
    width: 12px;
    height: 12px;
  }

  &--48 {
    width: 48px;
    height: 48px;
    line-height: 45px;
  }
}
