.breadcrumb {
  padding-left: 0;
  background-color: transparent;
  flex-wrap: nowrap;
  justify-items: center;

  @media (min-width: theme('screens.md')) {
    justify-items: flex-start;
  }
}

.breadcrumb-item {
  text-transform: uppercase;
  font-weight: theme('fontWeight.semibold');
  font-size: theme('fontSize.xs');
  white-space: nowrap;
  margin-right: 4px;
  display: flex;
  align-items: center;
  padding-left: 10px;

  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.sm');
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
}

.breadcrumb-item {
  &.active {
    color: theme('colors.brand.navy');
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: theme('colors.brand.navy');
    line-height: 1;

    &:hover {
      color: theme('colors.orange.DEFAULT');
      text-decoration: underline;
    }
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
