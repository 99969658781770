.chat-fc-form-outer {
  font-family: theme('fontFamily.sans') !important;
}

.chat-fc-form-outer div.fc-form {
  padding-top: 20px !important;
}

.chat-fc-form-outer div.fc-form p {
  margin-bottom: 24px !important;
  font-weight: theme('fontWeight.normal');
}

.chat-fc-form-outer div.fc-form ul li {
  margin-bottom: 24px !important;
}

.chat-fc-form-outer div.fc-form ul li input {
  padding: 8px 6px !important;
  color: theme('colors.black.DEFAULT') !important;
  border-radius: theme('borderRadius.lg') !important;

  &:focus {
    box-shadow: none !important;
  }
}

.chat-fc-form-outer div.fc-form ul li label {
  color: theme('colors.grey.DEFAULT') !important;
  font-size: theme('fontSize.sm') !important;
}

.chat-fc-form-outer div.fc-form a.fc-button {
  border-radius: theme('borderRadius.lg') !important;
}
