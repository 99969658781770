.gift-card {
  &:focus {
    outline: none;
  }

  a {
    &:focus {
      outline: none;
    }
  }

  &__img {
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100px;
      border-top-left-radius: theme('borderRadius.lg');
      border-top-right-radius: theme('borderRadius.lg');

      @media (min-width: $ss-breakpoint-md) {
        height: 170px;
      }
    }

    &--checkout {
      img {
        height: 200px;
      }
    }
  }

  &__overlay {
    padding: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $ss-zindex-10;
    background-color: rgba($ss-black-color, 0.2);
    border-radius: $ss-border-radius-base;

    @media (min-width: $ss-breakpoint-md) {
      padding: 16px;
    }
  }

  &__border {
    border: 2px solid $white;
    height: 100%;
    color: $white;
    padding-left: 12px;
    padding-top: 12px;
    font-weight: $ss-font-weight-bold;
    text-transform: uppercase;
    font-size: $ss-font-size-sm;

    @media (min-width: $ss-breakpoint-md) {
      border-width: 4px;
    }
  }

  &__value {
    position: absolute;
    z-index: $ss-zindex-10;
    width: 100%;
    left: 0;
    bottom: 16px;
    line-height: 1;
    color: $white;
    font-size: $ss-font-size-title-3;
    font-weight: $ss-font-weight-bold;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-4;
    }
  }

  &__body {
    padding: 12px 0;

    @media (min-width: $ss-breakpoint-md) {
      padding: 24px 0;
    }
  }

  &__name {
    font-size: $ss-font-size-base;
    font-weight: $ss-font-weight-bold;
    color: $ss-black-color;
    margin-bottom: 8px;
    line-height: 1.3;
  }

  &__price {
    color: $ss-cyan-color;
    font-size: $ss-font-size-base;
    font-weight: $ss-font-weight-bold;
  }

  &__badge {
    background-color: $ss-yellow-color;
    border-radius: $ss-border-radius-base;
    padding: 4px 8px;
    color: $white;
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-sm;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
    }

    &--big {
      padding: 6px 8px;
      font-size: $ss-font-size-base;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-xl;
        padding: 8px 16px;
      }
    }
  }
}
