.section {
  padding-top: 80px;
  padding-bottom: 80px;

  @media (min-width: $ss-breakpoint-md) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  &.section-blog {
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: $ss-breakpoint-md) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  &--next-to-footer {
    margin-bottom: -32px;
  }

  &__title {
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-title-2;
    line-height: 1.4;
    margin-bottom: 32px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-4;
      margin-bottom: 48px;
    }
  }

  &__side-title {
    text-align: left;
    margin-bottom: 16px;
  }

  &__tagline {
    text-align: center;
  }

  &.travel-better {
    padding-bottom: 24px;
    margin-top: 160px;

    @media (min-width: $ss-breakpoint-md) {
      padding-bottom: 48px;
      margin-top: 96px;
    }

    img {
      margin-top: -166px;

      @media (min-width: $ss-breakpoint-md) {
        margin-top: -48px;
      }
    }
  }

  &__img {
    width: auto;
    max-height: 300px;

    @media (min-width: $ss-breakpoint-md) {
      max-height: 480px;
    }
  }
}
