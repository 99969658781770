.page-nav {
  background-color: $white;
  display: none;
  height: 0;
  padding: 0;
  border-bottom: 0;

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: start;
    white-space: nowrap;
    overflow-x: auto;

    @media (min-width: theme('screens.md')) {
      justify-content: center;
    }
  }

  &__item {
    display: inline-block;
    position: relative;
  }

  &__item + &__item {
    margin-left: 32px;

    &:before {
      content: '';
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background-color: theme('colors.white');
      position: absolute;
      top: 11px;
      left: -17px;
    }
  }

  &__link {
    cursor: pointer;
    font-family: theme('fontFamily.styleSans');
    color: theme('colors.white');
    font-size: theme('fontSize.base');
    font-weight: theme('fontWeight.semibold');

    &:hover,
    &.active {
      color: theme('colors.orange.DEFAULT');
    }
  }
}
