.ql-container {
  font-family: theme('fontFamily.sans');
  font-size: theme('fontSize.base');
}

.rich-textarea {
  width: 100%;
  margin-bottom: 2px;

  .ql-container.ql-snow {
    border: 0;
  }

  .ql-editor {
    padding: 0;
    line-height: 18px;

    @extend .custom-scrollbar;
  }

  .ql-editor.ql-blank::before {
    left: 0;
    font-style: normal;
  }
}
