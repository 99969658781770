.select-box {
  &__popup {
    position: absolute;
    z-index: $ss-zindex-100;
    width: 200px;
    background-color: $white;
    top: calc(100% + 4px);
    border: 1px solid $ss-border-color;
    display: flex;
    height: 100px;
    right: 0;
    padding: 16px;

    @media (min-width: $ss-breakpoint-md) {
      right: 51px;
    }
  }
}

.search-box {
  &__popup {
    z-index: $ss-zindex-100;
    background-color: $white;
    overflow-y: auto;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    border: 0;
    padding: 16px;
    position: fixed;

    @media (min-width: $ss-breakpoint-lg) {
      position: absolute;
      top: calc(100% + 4px);
      width: 800px;
      height: 370px;
      right: auto;
      left: 0;
      border: 1px solid $ss-border-color;
      padding: 24px;

      &--hero {
        position: absolute;
        top: calc(100% + 4px);
        width: 100%;
        height: 370px;
        border: 1px solid $ss-border-color;
        padding: 24px;
      }

      &--select {
        width: 200px;
        height: 100px;
        right: 51px;
        left: auto;
      }

      &--date {
        width: 400px;
        height: 430px;
        right: -249px;
        left: auto;
      }
    }
  }

  &__popup-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__nav {
    width: 150px;
    flex: 0 0 auto;
    display: none;
    flex-direction: column;

    @media (min-width: $ss-breakpoint-md) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__nav-item {
    flex: 1 0 auto;
  }

  &__nav-item-inner {
    padding: 16px 0;
    text-align: center;
    border-right: 1px solid $ss-border-color;
    font-weight: $ss-font-weight-bold;
    color: $ss-black-color;
    position: relative;
    height: 100%;
    cursor: pointer;
    display: block;

    &:hover,
    &.active {
      background-color: $ss-cyan-color;
      color: $white;
      border-right-color: $ss-cyan-color;

      &:before {
        @include triangle('right', $ss-cyan-color, 6px);
        position: absolute;
        right: -10px;
        top: 22px;
      }
    }
  }

  &__nav-item + &__nav-item {
    border-top: 1px solid $ss-border-color;
  }

  &__result {
    width: 100%;
    color: theme('colors.brand.navy');
  }

  &__result + &__result {
    border-top: 1px solid theme('colors.grey.lighter');
    margin-top: 16px;
    padding-top: 16px;
  }

  &__result-item {
    padding: 8px 0;
    font-size: $ss-font-size-sm;
    display: flex;
    align-items: center;
  }

  &__result-item-img {
    flex: 0 0 auto;
    object-fit: cover;
    width: 52px;
    height: 44px;
    border-radius: theme('borderRadius.lg');
    margin-right: 16px;
  }

  &__result-item-title {
    color: theme('colors.brand.navy');
  }

  &__result-item-subtitle {
    color: theme('colors.grey.DEFAULT');
  }

  &__result-item-icon {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    line-height: 64px;
    background: theme('colors.brand.green');
    margin-right: 20px;
    margin-left: 4px;
    text-align: center;

    img {
      width: 24px;
      height: auto;
    }
  }

  &__body {
    height: calc(100% - 213px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: $ss-breakpoint-lg) {
      height: 100%;
    }
  }

  &__footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2px;

    @media (min-width: $ss-breakpoint-lg) {
      display: none;
    }
  }

  &__input {
    height: 48px;
    border-radius: $ss-border-radius-lg;
    border-right: 0;
  }

  &__btn-clear {
    cursor: pointer;

    &.active {
      svg {
        display: inline-block;
      }
    }

    svg {
      display: none;
    }
  }
}

.search-box__popup {
  .tab-content > .tab-pane {
    display: block;

    @media (min-width: $ss-breakpoint-md) {
      display: none;
    }
  }

  .tab-content > .active {
    @media (min-width: $ss-breakpoint-md) {
      display: block;
    }
  }

  .fade:not(.show) {
    opacity: 1;

    @media (min-width: $ss-breakpoint-md) {
      opacity: 0;
    }
  }
}

.stacked-input-group {
  &__item {
    display: flex;

    &:first-child {
      .input-group-text {
        border-top-left-radius: $ss-border-radius-lg;
      }
      .form-control {
        border-top-right-radius: $ss-border-radius-lg;
      }
    }

    &:last-child {
      .input-group:first-child {
        .input-group-text {
          border-bottom-left-radius: $ss-border-radius-lg;
        }
      }

      .input-group:last-child {
        .input-group-text {
          border-left: 0;
        }

        .form-control {
          border-bottom-right-radius: $ss-border-radius-lg;
        }
      }
    }

    .input-group.selected {
      svg {
        color: $ss-cyan-color;
      }

      input {
        color: $ss-cyan-color;
      }
    }

    .form-control,
    .input-group-text {
      padding-top: 11px;
      padding-bottom: 11px;
    }

    .form-control {
      height: 48px;
    }
  }

  &__item + &__item {
    margin-top: -1px;
  }
}
