.customer-review {
  position: relative;

  &__content {
    background-color: $white;
    padding: 24px 16px;

    @media (min-width: $ss-breakpoint-md) {
      position: absolute;
      width: 480px;
      padding: 32px 48px;
      left: 120px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 48px;
        height: 48px;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__body {
    font-size: $ss-font-size-lg;
    font-weight: $ss-font-weight-bold;
    margin-bottom: 24px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-2;
    }
  }

  &__img {
    height: 260px;
    position: relative;

    @media (min-width: $ss-breakpoint-md) {
      width: 50%;
      height: 600px;
      margin-left: 50%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__location {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }
}

.carousel-collection-activity-review-container {
  width: calc(100% - 122px);

  @media (min-width: $ss-breakpoint-md) {
    width: calc(100% - 150px);
  }
}
