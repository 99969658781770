// override slick css
.slick-arrow {
  position: absolute;
  z-index: $ss-zindex-10;
  top: 50%;
  transform: translateY(-50%);
  color: $white;
  font-weight: $font-weight-bold;
  line-height: 24px;
  font-size: $ss-font-size-lg;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev,
.slick-next {
  width: 48px;
  height: 48px;
  font-size: 24px;
  line-height: 42px;
  background-color: theme('colors.grey.lighter');
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  &:hover {
    svg {
      color: theme('colors.brand.navy');
    }
  }

  &.slick-disabled {
    display: none !important;
  }

  svg {
    color: theme('colors.brand.navy');
    width: 32px;
    height: 32px;
  }
}

.slick-prev {
  left: 5px;

  @media (min-width: $ss-breakpoint-lg) {
    left: 0;
  }
}

.slick-next {
  right: 5px;

  @media (min-width: $ss-breakpoint-lg) {
    right: 0;
  }
}

.slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 15px;
  padding: 0;
  margin: 0;
  text-align: center;
}

.slick-dots {
  li {
    font-size: 0;
    display: inline-block;
    border: 1px solid theme('colors.grey.lighter');
    border-radius: 50%;
    padding: 4px;
    transition: all 0.2s ease-in-out;
    background-color: theme('colors.grey.lighter');

    &.slick-active {
      border-color: theme('colors.purple.DEFAULT');
      background-color: theme('colors.purple.DEFAULT');
    }

    button {
      display: none;
    }
  }

  li + li {
    margin-left: 10px;
  }
}

.slider-v2 {
  .slick-arrow {
    position: absolute;
    z-index: theme('zIndex.10');
    top: auto;
    bottom: -60px;
    transform: translateX(-50%);
    color: theme('colors.white');
    line-height: 28px;
    display: inline-block;
    width: 28px;
    height: 28px;
    text-align: center;
    cursor: pointer;
  }

  .slick-prev,
  .slick-next {
    border-radius: unset;
    width: 28px;
    height: 28px;
    box-shadow: none;
    font-size: 28px;
    line-height: 34px;
    background-color: transparent;

    &:hover {
      svg {
        color: theme('colors.brand.navy');
      }
    }

    &.slick-disabled {
      opacity: 0.7;
      display: block !important;
      cursor: default;

      svg {
        color: theme('colors.brand.navy');

        &:hover {
          color: theme('colors.brand.navy');
        }
      }
    }

    svg {
      color: theme('colors.brand.navy');
      width: 28px;
      height: 28px;
    }
  }

  .slick-prev {
    right: auto;
    left: 50%;
    margin-left: -31px;
  }

  .slick-next {
    right: auto;
    left: 50%;
    margin-left: 10px;
    padding-left: 13px;
    text-align: left;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 1px;
      display: inline-block;
      height: 32px;
      border-left: 2px solid theme('colors.white');
      transform: rotate(35deg);
    }
  }
}

.slider-v2.slider-v2-green-dots {
  .slick-dots {
    li {
      &.slick-active {
        border-color: theme('colors.brand.green');
        background-color: theme('colors.brand.green');
      }
    }
  }
}

.slider-v2.slider-collection-best-tdd {
  .slick-arrow {
    top: 48%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 42px;
    background-color: theme('colors.grey.lighter');
    border-radius: 50%;
    transition: all 0.2s ease-in-out;

    &:hover {
      svg {
        color: theme('colors.orange.DEFAULT');
      }
    }

    &.slick-disabled {
      display: none !important;
    }

    svg {
      color: theme('colors.brand.navy');
      width: 32px;
      height: 32px;
    }
  }

  .slick-prev {
    left: 5px;
    right: auto;

    @media (min-width: $ss-breakpoint-lg) {
      left: 22px;
    }
  }

  .slick-next {
    left: auto;
    right: 5px;
    padding-left: 10px;

    &:before {
      display: none;
    }

    @media (min-width: $ss-breakpoint-lg) {
      right: -4px;
    }
  }

  .slick-dots {
    bottom: -36px;
  }
}
