.chapter {
  &__img {
    @include img-as-bg;
    height: 280px;
    margin: 0 -15px;

    @media (min-width: $ss-breakpoint-md) {
      height: 380px;
      margin: 0;
    }
  }

  &__title-main {
    line-height: 1;
    font-size: $ss-font-size-xl;
    font-weight: $font-weight-bold;

    @media (min-width: $ss-breakpoint-lg) {
      font-size: $ss-font-size-title-2;
    }
  }

  &__title-side {
    line-height: 1;
    font-size: $ss-font-size-title-4;
    font-weight: $font-weight-bold;
    display: none;

    @media (min-width: $ss-breakpoint-lg) {
      display: block;
    }
  }

  &__title {
    line-height: 1.2;
    font-size: $ss-font-size-xl;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
    display: block;

    @media (min-width: $ss-breakpoint-lg) {
      display: none;
    }
  }

  &__list + &__list {
    margin-top: 15px;
  }

  &__list-item {
    display: inline-block;
    font-size: $ss-font-size-sm;
    margin-right: 15px;
    margin-bottom: 5px;
    line-height: 1;

    i {
      color: $ss-cyan-color;
      margin-right: 5px;
    }

    .badge {
      font-size: 14px;
      font-weight: $font-weight-normal;

      i {
        color: $white;
      }
    }

    &--line + &--line {
      padding-left: 15px;
      border-left: 1px solid $ss-black-color;
    }
  }

  &__desc {
    margin-top: 16px;
    line-height: 1.8;

    @media (min-width: $ss-breakpoint-lg) {
      margin-top: 0;
    }
  }

  &__subtitle {
    color: $ss-grey-light-color;
    font-size: $ss-font-size-lg;
    font-weight: $font-weight-bold;
		margin-bottom: 0;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
    }
  }

  &__divider {
    border-top: 4px solid $ss-border-color;
    margin: 32px -15px;

    @media (min-width: $ss-breakpoint-md) {
      border-top: 1px solid $ss-border-color;
      margin: 48px 0;
    }
  }
}
