// tooltip bootstrap v5
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  @include font-size($tooltip-font-size);
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show { opacity: $tooltip-opacity; }

  .tooltip-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .tooltip-arrow {
    bottom: 0;

    &::before {
      top: -1px;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width * .5) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-end {
  padding: 0 $tooltip-arrow-height;

  .tooltip-arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: -1px;
      border-width: ($tooltip-arrow-width * .5) $tooltip-arrow-height ($tooltip-arrow-width * .5) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .tooltip-arrow {
    top: 0;

    &::before {
      bottom: -1px;
      border-width: 0 ($tooltip-arrow-width * .5) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-start {
  padding: 0 $tooltip-arrow-height;

  .tooltip-arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: -1px;
      border-width: ($tooltip-arrow-width * .5) 0 ($tooltip-arrow-width * .5) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[data-popper-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[data-popper-placement^="right"] {
    @extend .bs-tooltip-end;
  }
  &[data-popper-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[data-popper-placement^="left"] {
    @extend .bs-tooltip-start;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}

.tooltip-left .tooltip-inner {
  text-align: left;
}

.tooltip-inner {
  max-width: 240px;

  &--lg {
    max-width: 400px;
  }
}


.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: $ss-true-black-color;
}


.tooltip-num-guest {
  z-index: $ss-zindex-70;
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $ss-cyan-color;
    max-width: 400px;
    padding: 12px 16px;
    font-size: $ss-font-size-xs;
    font-weight: $ss-font-weight-bold;
    border-radius: $ss-border-radius-base;

    @media (min-width: $ss-breakpoint-md) {
      padding: 16px 24px;
      font-size: $ss-font-size-sm;
    }
  }

  .tooltip-arrow::before {
    border-bottom-color: $ss-cyan-color;
  }
}

.tooltip-price {
  z-index: 1070;

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $ss-cyan-color;
    max-width: 320px;
    padding: 12px 16px;
    font-size: $ss-font-size-xs;
    font-weight: $ss-font-weight-bold;
    border-radius: $ss-border-radius-base;

    @media (min-width: $ss-breakpoint-md) {
      padding: 16px 24px;
      font-size: $ss-font-size-sm;
    }
  }

  .tooltip-arrow::before {
    border-bottom-color: $ss-cyan-color;
    border-top-color: $ss-cyan-color;
  }

  .tooltip-close {
    position: absolute;
    top: 4px;
    right: 6px;
    color: $white;
    font-size: $ss-font-size-title-2;
    line-height: 1;
    cursor: pointer;
  }
}

.tooltip-highlight {
  .tooltip-inner {
    background-color: $ss-black-color;
  }

  .tooltip-arrow::before {
    border-bottom-color: $ss-black-color;
  }
}
