.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 240px;

  @media (min-width: $ss-breakpoint-md) {
    height: 400px;
  }

  iframe {
    width: 220%;
    height: 220%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--feature {
    height: 200px;

    @media (min-width: $ss-breakpoint-md) {
      height: 320px;
    }
  }

  &--full {
    iframe {
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: $ss-zindex-1000;
      top: 0;
      left: 0;
      transform: none;
    }
  }

  &.modal-about-video {
    margin: 24px 0;

    @media (min-width: $ss-breakpoint-md) {
      width: 800px;
      height: 600px;
      margin: 24px auto;
    }

    iframe {
      position: static;
      transform: none;
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }

    .video-control {
      display: none;
    }
  }
}

.video-control {
  bottom: 0;
  left: 0;
  padding: 12px;
  position: absolute;
  z-index: $ss-zindex-50;
  width: 100%;
  display: none;
  align-items: center;
  background: rgba($ss-true-black-color, 0.5);

  &.active {
    display: flex;
  }

  svg {
    color: $white;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__sound {
    margin-left: 24px;
  }

  &__duration {
    margin-left: 24px;
    margin-right: 24px;
    flex-grow: 1;
    height: 2px;
    background: $ss-grey-lightest-color;
    position: relative;
  }

  &__timer {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 0;
    background: $ss-cyan-color;
  }

  &--fixed {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: $ss-zindex-1200;
    display: flex;
  }

  &__btn-close {
    position: fixed;
    z-index: $ss-zindex-1200;
    top: 16px;
    right: 16px;
    padding: 8px;
    background: rgba($ss-true-black-color, 0.5);
    cursor: pointer;
    svg {
      width: 48px;
      height: 48px;
      color: $white;
    }
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-overlay-with-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 48px);

  @media (min-width: $ss-breakpoint-md) {
    display: none;
  }
}

.video-control__btn-lightbox {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: $ss-zindex-50;

  @media (min-width: $ss-breakpoint-md) {
    top: auto;
    bottom: 12px;
  }

  .btn {
    background-color: $white;
    font-size: $ss-font-size-sm;
    font-weight: $ss-font-weight-bold;
    border-radius: $ss-border-radius-base;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
