.referral-link {
  display: flex;
  align-items: center;
  background-color: $white;
  max-width: 600px;
  margin: 0 auto 24px;

  &__input {
    display: block;
    background-color: $white;
    outline: none;
    padding: 16px;
    color: $ss-black-color;
    font-size: $ss-font-size-sm;
    border: 0;
    flex-grow: 1;

    @media (min-width: $ss-breakpoint-sm) {
      font-size: $ss-font-size-base;
    }
  }

  &__btn {
    font-weight: $ss-font-weight-bold;
    color: $ss-black-color;
    padding-right: 16px;
    margin-left: auto;
    cursor: pointer;
  }

  &__icon {
    color: $ss-black-color;
  }

  &--light {
    background-color: $ss-grey-lightest-color;
  }

  &--light &__input {
    background-color: $ss-grey-lightest-color;
    color: $ss-black-color;
  }

  &--white {
    background-color: $white;
  }

  &--white &__input {
    background-color: $white;
    color: $ss-black-color;
  }

  &--yellow &__btn,
  &--light &__btn {
    color: $ss-black-color;
  }

  &--yellow &__icon,
  &--light &__icon {
    color: $ss-black-color;
  }
}
