.cartContainer {
  height: 100%;
  top: 0px;

  @media (min-width: theme('screens.md')) {
    top: 72px;
    height: calc(100% - 72px);
  }
}

.cartWithNoHeaderContainer {
  height: 100%;
  top: 0px;

  @media (min-width: theme('screens.md')) {
    top: 64px;
    height: calc(100% - 64px);
  }
}

.cartWithEventHeaderContainer {
  height: 100%;
  top: 0px;

  @media (min-width: theme('screens.md')) {
    top: 57px;
    height: calc(100% - 57px);
  }
}
