.blog {
  &__excerpt {
    margin-bottom: 24px;
    color: $ss-grey-color;
  }

  &__meta {
    margin-bottom: 32px;

    @media (min-width: $ss-breakpoint-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__cover-img {
    object-fit: cover;
    width: 100%;
    height: 280px;
    margin-bottom: 32px;

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 40px;
      height: 500px;
    }
  }

  &__footer {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid $ss-border-color;
  }

  &__table-content {
    border: 1px solid theme('colors.brand.green');
    background-color: theme('colors.white');
    padding: 16px;
    margin-bottom: 32px;
    border-radius: 10px;

    @media (min-width: $ss-breakpoint-md) {
      padding: 24px 32px;
    }
  }

  &__table-content-title {
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-xl;
    margin-bottom: 16px;
  }

  &__table-content-list {
    padding-left: 17px;

    li + li {
      margin-top: 12px;
    }

    a {
      cursor: pointer;
      color: theme('colors.brand.navy');

      &:hover {
        &:not([href]):not([tabindex]) {
          text-decoration: underline;
        }
      }
    }
  }

  &__list {
  }

  &__list-item {
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $ss-border-color;

    &:first-child {
      margin-top: 16px;
    }

    @media (min-width: $ss-breakpoint-md) {
      flex-direction: row;
      padding-top: 48px;
      padding-bottom: 48px;

      &:first-child {
        margin-top: 32px;
      }
    }
  }

  &__list-item-media {
    flex: 0 0 auto;
    order: 2;
    width: 100%;

    @media (min-width: $ss-breakpoint-md) {
      order: initial;
      width: 40%;
    }

    img {
      width: 100%;
      height: 220px;

      @media (min-width: $ss-breakpoint-md) {
        width: 290px;
      }
    }

    iframe {
      width: 100%;
    }
  }

  &__list-item-img {
    object-fit: cover;
    width: 100%;
  }

  &__list-item-media + &__list-item-text {
    @media (min-width: $ss-breakpoint-md) {
      margin-left: 24px;
    }
  }

  &__list-item-text + &__list-item-media {
    @media (min-width: $ss-breakpoint-md) {
      margin-left: 24px;
    }
  }

  &__list-item-text {
    flex: 0 0 auto;
    width: 100%;
    order: 1;
    margin-bottom: 24px;

    @media (min-width: $ss-breakpoint-md) {
      width: 60%;
      margin-bottom: 0;
      flex-basis: 1%;
      flex-grow: 1;
      order: initial;
      display: flex;
    }
  }

  &__list-item-num {
    margin-top: -2px;
    line-height: 1;
    font-weight: theme('fontWeight.semibold');
    color: theme('colors.brand.navy');
    font-size: 48px;
    text-align: center;
    margin-bottom: 32px;

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 0;
      text-align: left;
      flex: 0 0 auto;
      width: 52px;
      margin-right: 20px;
    }

    &--black {
      color: $ss-black-color;
    }
  }

  &__list-item-body {
    flex: 1 1 auto;
  }

  &__list-item-desc {
    a {
      color: theme('colors.brand.navy');
      text-decoration: underline;

      &:hover {
        font-weight: theme('fontWeight.semibold');
      }
    }
  }

  &__list-item-title {
    font-family: theme('fontFamily.styleSans');
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-xl;
    margin-bottom: 16px;
    line-height: 1.2;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-2;
    }
  }

  &__list-item-btn-book {
    text-align: center;
    @media (min-width: $ss-breakpoint-md) {
      text-align: left;
    }

    a {
      text-transform: lowercase;
      display: inline-block;
      color: theme('colors.brand.navy');
      padding: 6px 32px;
      @media (min-width: $ss-breakpoint-md) {
        color: theme('colors.brand.navy');
        padding: 0;
        border: 0;
      }
    }
  }

  &__list-item-link {
    text-align: center;

    @media (min-width: $ss-breakpoint-md) {
      display: block;
      text-align: left;
    }

    a {
      display: inline-block;
      color: $ss-black-color;
      padding: 6px 32px;
      border: 1px solid $ss-black-color;

      @media (min-width: $ss-breakpoint-md) {
        padding: 0;
        border: 0;
        font-weight: $ss-font-weight-bold;
        text-decoration: underline;
        color: $ss-black-color;

        &:hover {
          color: $ss-cyan-color;
        }
      }
    }
  }

  &__body {
    font-size: theme('fontSize.base');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.lg');
    }

    iframe {
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      display: block;
    }

    a {
      color: theme('colors.brand.navy');
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 4.17%, rgba(255, 255, 255, 0.00) 30.21%, rgba(255, 255, 255, 0.00) 38.54%, #E4E480 46.88%);
    }

    h1 {
      margin-top: 48px;
      margin-bottom: 48px;
      font-size: $ss-font-size-title-3;

      @media (min-width: $ss-breakpoint-md) {
        margin-top: 64px;
        margin-bottom: 64px;
        font-size: $ss-font-size-title-4;
      }
    }

    h2 {
      margin-top: 48px;
      margin-bottom: 32px;
      font-size: $ss-font-size-title-2;

      @media (min-width: $ss-breakpoint-md) {
        margin-top: 48px;
        margin-bottom: 48px;
        font-size: $ss-font-size-title-3;
      }
    }

    h3 {
      margin-top: 24px;
      margin-bottom: 24px;
      font-size: $ss-font-size-title-1;

      @media (min-width: $ss-breakpoint-md) {
        margin-top: 32px;
        margin-bottom: 32px;
        font-size: $ss-font-size-title-2;
      }
    }

    h4 {
      font-size: $ss-font-size-lg;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-title-1;
      }
    }

    h5 {
      font-size: $ss-font-size-lg;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-xl;
      }
    }

    h6 {
      font-size: $ss-font-size-base;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-lg;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: $ss-font-weight-bold;
    }

    h1,
    h2,
    h3 {
      text-align: center;
      line-height: 1.2;
    }

    h4,
    h5,
    h6,
    ul,
    ol,
    div,
    p {
      margin-bottom: 24px;
    }

    ol,
    ul {
      li + li {
        margin-top: 8px;
      }
    }

    .block-img {
      text-align: center;
    }

    .block-img__caption {
      margin-top: 16px;
      font-style: italic;
      color: $ss-grey-light-color;
    }

    img {
      object-fit: cover;
      max-width: 100%;
      height: auto;
    }

    .card-oembed {
      max-width: 600px;
      margin: 0 auto;
    }

    .card-oembed__title,
    .card-oembed__subtitle,
    .card-oembed__img {
      margin-bottom: 8px;
    }
  }
}

.card-oembed {
  &__img {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    img {
      object-fit: cover;
      width: 100%;
      height: 320px;
      border-radius: theme('borderRadius.lg');
      border: 2px solid theme('colors.brand.green');

      @media (min-width: $ss-breakpoint-md) {
        height: 260px;
      }
    }
    margin-bottom: 16px;
  }

  &:hover {
    .card-oembed__img {
      opacity: 0.7;
    }
  }

  &--disabled {
    &:hover {
      .card-oembed__img {
        opacity: 1;
      }
    }
  }

  &__subtitle {
    display: none;
    color: $ss-grey-light-color;
    font-size: theme('fontSize.sm');
    margin-bottom: 8px;
  }

  &__title {
    font-family: theme('fontFamily.styleSans');
    font-weight: theme('fontWeight.semibold');
    color: theme('colors.brand.navy');
    margin-bottom: 4px;
  }

  &__text {
    color: theme('colors.brand.navy');
  }
}

.section-blog {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding-bottom: 8px;
    position: relative;
    justify-content: center;

    @media (min-width: $ss-breakpoint-md) {
      border-bottom: 1px solid $ss-border-color;
      justify-content: space-between;
    }
  }

  &__title--yellow {
    @media (max-width: $ss-breakpoint-md - 1) {
      .section-blog__title-text {
        color: $ss-yellow-color;
      }
      .section-blog__title-link svg {
        color: $ss-yellow-color;
      }
    }
  }

  &__title--cyan {
    @media (max-width: $ss-breakpoint-md - 1) {
      .section-blog__title-text {
        color: $ss-cyan-color;
      }
      .section-blog__title-link svg {
        color: $ss-cyan-color;
      }
    }
  }

  &__title--purple {
    @media (max-width: $ss-breakpoint-md - 1) {
      .section-blog__title-text {
        color: $ss-purple-color;
      }
      .section-blog__title-link svg {
        color: $ss-purple-color;
      }
    }
  }

  &__title-text {
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-title-2;
    line-height: 1;
    margin-bottom: 0;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-3;
    }
  }

  &__title-link {
    color: $ss-grey-light-color;
    @extend .stretched-link;

    &:hover {
      color: $ss-cyan-color;
    }

    span {
      display: none;
    }

    @media (min-width: $ss-breakpoint-md) {
      span {
        display: inline;
      }

      &:after {
        display: none;
      }
    }
  }
}

iframe.instagram-media {
  margin: 0 auto !important;
}

.normal-article {
  color: theme('colors.brand.navy');
  font-size: theme('fontSize.base');

  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.lg');
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  p,
  ul {
    margin-bottom: 24px;
  }

  div,
  p {
    min-height: 1px;
  }

  h1 {
    line-height: 1.2;
    font-size: theme('fontSize.3xl');
    margin-bottom: theme('spacing.6');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.6xl');
      margin-bottom: theme('spacing.8');
    }
  }

  h2 {
    line-height: 1.2;
    font-size: theme('fontSize.2xl');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.4xl');
    }
  }

  h3 {
    line-height: 1.2;
    font-size: theme('fontSize.xl');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.2xl');
    }
  }

  h4 {
    line-height: 1.4;
    font-size: theme('fontSize.lg');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.xl');
    }
  }

  h5 {
    line-height: 1.4;
    font-size: theme('fontSize.base');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.lg');
    }
  }

  h6 {
    line-height: 1.4;
    font-size: theme('fontSize.sm');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.base');
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: theme('colors.brand.navy');
    font-weight: theme('fontWeight.semibold');
    font-family: theme('fontFamily.styleSans');

    a {
      color: theme('colors.brand.navy');
    }
  }

  ol > li,
  ul > li {
    margin-bottom: 12px;
  }

  pre {
    background-image: url('/images/purple_quote.png');
    background-position: 16px top;
    background-repeat: no-repeat;
    background-size: 36px 32px;
    padding-left: 60px;
    display: block;
    margin: 32px auto;
    width: 100%;
    font-size: theme('fontSize.xl');
    overflow: hidden;
    word-break: normal;
    white-space: normal;
    font-family: theme('fontFamily.styleSans');
    font-weight: theme('fontWeight.semibold');
    border-left: 8px solid theme('colors.brand.navy');
    font-size: theme('fontSize.xl');
    color: theme('colors.brand.navy');

    @media (min-width: theme('screens.md')) {
      width: 100%;
      font-size: theme('fontSize.4xl');
      background-position: 32px top;
      background-size: 55px 48px;
      padding-left: 100px;
      border-left: 8px solid theme('colors.brand.navy');
      margin: 48px 0;
    }
  }

  a {
    color: theme('colors.brand.navy');
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 4.17%, rgba(255, 255, 255, 0.00) 30.21%, rgba(255, 255, 255, 0.00) 38.54%, #E4E480 46.88%);
  }

  a.btn {
    background-image: none;
  }

  img {
    display: block;
    width: 100%;
    height: 240px;
    border-radius: theme('borderRadius.lg');
    margin-bottom: theme('spacing[2.5]');
    object-fit: cover;
    border: 2px solid theme('colors.brand.green');

    @media (min-width: theme('screens.md')) {
      height: 420px;
    }
  }

  iframe {
    max-width: 100%;
  }

  .block-img {
    margin: 32px 0;

    @media (min-width: theme('screens.md')) {
      margin: 40px 0;
    }
  }

  .block-img__caption {
    color: theme('colors.grey.light');
    font-size: theme('fontSize.xs');

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.sm');
    }
  }
}
