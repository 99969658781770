.option-datepicker {
  width: 100%;

  @media (min-width: $ss-breakpoint-md) {
    width: 462px;
  }

  &__input {
    .form-control {
      font-size: $ss-font-size-sm;
      height: 40px;
      line-height: 20px;
      border-radius: theme('borderRadius.lg');
    }

    .input-group-text {
      border-radius: theme('borderRadius.lg');
      width: 44px;
    }
  }

  &__btn-clear {
    padding-top: 4px;
    margin-left: 16px;
    font-size: $ss-font-size-sm;
    text-decoration: underline;
    line-height: 1;
    cursor: pointer;
  }

  &__footer {
    border-top: 1px solid $ss-border-color;
    position: absolute;
    z-index: $ss-zindex-10;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 16px;
  }
}
