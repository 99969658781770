.card {
  border: 0;

  &.card-boat {
    flex: none;
    width: 300px;
    position: relative;
    cursor: pointer;

    .card-img-top {
      @include img-as-bg;
      width: 100%;
      height: 200px;
      background-color: $ss-grey-light-color;
      text-align: center;

      svg {
        margin-top: 68px;
      }
    }

    .card-title {
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
    }

    .card-body {
      border: 1px solid $ss-border-color;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .card-text {
      font-size: $ss-font-size-sm;
    }

    .card-boat__icon {
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  &.card-highlight {
    .card-img-top {
      @include img-as-bg;
      width: 100%;
      height: 150px;
      background-color: $ss-grey-light-color;
      text-align: center;
      border-radius: 0;
    }
    .card-title {
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
      text-align: center;
    }

    .card-body {
      padding: 5px 5px 10px 5px;
      text-align: center;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: $ss-border-color;
    }

    .card-footer {
      background-color: $white;
      padding: 5px;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: $ss-border-color;
      display: flex;
      justify-content: space-between;
    }
  }

  &.card-activity {
    box-shadow: none;
    margin-bottom: 16px;
    border-radius: 0;
    position: relative;

    @media (min-width: $ss-breakpoint-md) {
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        background-color: rgba($white, 0.3);
      }
    }

    .card-activity__option-counter {
      background: $white;
      font-size: $ss-font-size-sm;
      border: 1px solid $ss-black-color;
      white-space: nowrap;
      border-radius: 4px;
      padding: 6px 12px 4px;
      line-height: 1;
      align-self: start;
      display: inline-block;
    }

    &:hover {
      @media (min-width: $ss-breakpoint-md) {
        &:after {
          display: block;
        }
      }

      a {
        text-decoration: underline;
      }
    }

    .card-title {
      font-size: $ss-font-size-base;
      margin-bottom: 8px;
      font-weight: $ss-font-weight-bold;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-xl;
        margin-bottom: 0;
      }

      a {
        color: $body-color;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .card-img-top {
      height: 210px;
      border-radius: 4px;
    }

    .card-body {
      padding: 10px 0;

      @media (min-width: $ss-breakpoint-md) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .card-image__main {
      margin-bottom: 1px;

      img {
        object-fit: cover;
        width: 100%;
        height: 120px;
        border-top-left-radius: $ss-border-radius-base;
        border-top-right-radius: $ss-border-radius-base;
        background-color: $ss-grey-lightest-color;

        @media (min-width: $ss-breakpoint-md) {
          height: 185px;
        }
      }
    }

    .card-image__sub {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-image__sub-img {
      width: calc(100% / 3);

      img {
        background-color: $ss-grey-lightest-color;
        flex-grow: 1;
        width: 100%;
        height: 52px;
        object-fit: cover;

        @media (min-width: $ss-breakpoint-md) {
          height: 80px;
        }
      }

      &:first-child {
        img {
          border-bottom-left-radius: $ss-border-radius-base;
        }
      }

      &:last-child {
        img {
          border-bottom-right-radius: $ss-border-radius-base;
        }
      }

      + .card-image__sub-img {
        margin-left: 1px;
      }
    }

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 32px;
    }

    .card-title {
      margin-bottom: 4px;

      @media (min-width: $ss-breakpoint-md) {
        margin-bottom: 8px;
      }
    }

    .card-meta {
      margin-bottom: 4px;

      @media (min-width: $ss-breakpoint-md) {
        margin-bottom: 8px;
      }
    }
  }

  &.card--border {
    border: 1px solid $ss-border-color;
  }

  &.card-blog {
    box-shadow: none;

    .card-img-top {
      margin-bottom: 16px;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      .card-img-top {
        opacity: 0.7;
      }
    }

    .card-title {
      margin-top: 12px;
      margin-bottom: 8px;
      font-size: $ss-font-size-lg;
      font-weight: $ss-font-weight-bold;
      line-height: 1.2;

      a {
        color: $ss-black-color;
      }
    }

    .card-body {
      padding: 0;
    }

    .card-blog__badge {
      padding-left: 16px;
      line-height: 1;
      position: relative;
      font-size: $ss-font-size-sm;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: -2px;
        width: 4px;
        height: 15px;
        background-color: transparent;
      }

      &--cyan {
        color: $ss-cyan-color;

        &:before {
          background-color: $ss-cyan-color;
        }
      }

      &--purple {
        color: $ss-purple-color;

        &:before {
          background-color: $ss-purple-color;
        }
      }

      &--yellow {
        color: $ss-yellow-color;

        &:before {
          background-color: $ss-yellow-color;
        }
      }

      &--green {
        color: $ss-green-color;

        &:before {
          background-color: $ss-green-color;
        }
      }

      &--blue {
        color: $ss-blue-color;

        &:before {
          background-color: $ss-blue-color;
        }
      }
    }

    .card-img-top {
      object-fit: cover;
      width: 100%;
      height: 240px;

      &--big {
        @media (min-width: $ss-breakpoint-md) {
          height: 320px;
        }
      }
    }

    .card-desc {
      color: $ss-grey-color;
      margin-bottom: 8px;
    }
  }
}

.card-accommodation {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  &__body {
    padding-bottom: 15px;
  }

  &__info {
    padding: 0 8px;
  }

  .btn {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .carousel-activity-sao-image .carousel-item img {
    height: 240px;

    @media (min-width: $ss-breakpoint-md) {
      height: 300px;
    }
  }

  &--section {
    max-width: 100%;
  }

  &--section .carousel-activity-sao-image .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 200px;

    @media (min-width: $ss-breakpoint-md) {
      height: 360px;
    }
  }
}

.card.card-overlay {
  &:hover {
    opacity: 0.7;
  }

  img {
    height: 301px;
  }

  .card-img-overlay {
    color: $white;
    font-size: $ss-font-size-title-2;
    font-weight: $ss-font-weight-bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.card-blank {
  height: 260px;
  border-radius: $ss-border-radius-lg;
  border: 1px solid $ss-border-color;
  display: block;
  position: relative;

  @media (min-width: $ss-breakpoint-md) {
    height: 260px;
  }

  &--activity {
    height: 173px;

    @media (min-width: $ss-breakpoint-md) {
      height: 266px;
    }
  }

  &--corp {
    height: 248px;
    border-radius: $ss-border-radius-lg;
  }

  &--collection {
    height: 285px;

    @media (min-width: $ss-breakpoint-md) {
      height: 384px;
    }
  }

  &--hero-country {
    padding: 0 16px;
    width: calc(100% / 3);
    border: 0;
    height: auto;

    @media (min-width: $ss-breakpoint-md) {
      background: $white;
      width: 184px;
      height: 200px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0;
    }
  }

  &__footer {
    color: $ss-black-color;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--hero-country &__footer {
    color: $white;
    position: static;
    justify-content: center;
    flex-direction: column;
    font-size: $ss-font-size-sm;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: $ss-cyan-color;
    border: 1px solid $white;
    margin: 0 auto;
    text-align: center;

    @media (min-width: $ss-breakpoint-md) {
      color: $ss-black-color;
      position: absolute;
      width: 100%;
      height: auto;
      background: transparent;
      border: 0;
      left: 0;
      bottom: 0;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $ss-font-size-base;
      text-align: left;
    }
  }
}

.card {
  &.card-admin {
    border: 1px solid $ss-border-color;

    .card-header {
      padding: 16px;
      cursor: pointer;
    }

    .card-header__btn {
      font-weight: $ss-font-weight-bold;
      cursor: pointer;
    }
  }
}

.card-admin + .card-admin {
  margin-top: 32px;
}

.card-option-scrollable {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 85%;
  max-width: 85%;
  padding: 0 6px;

  @media (min-width: $ss-breakpoint-md) {
    padding: 0 16px;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
}

.card-option-scrollable-blank {
  height: 260px;
}
