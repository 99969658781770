.navbar {
  padding: 8px;

  @media (min-width: $ss-breakpoint-md) {
    padding: 8px 16px;
  }
}

.navbar.navbar-primary {
  font-size: 16px;
  width: 100%;
}

.sidenav {
  position: fixed;
  z-index: $ss-zindex-100;
  top: 52px;
  left: 0;
  width: 100%;
  height: calc(100% - 52px);
  background-color: transparent;
  display: none;

  &.active {
    display: block;
  }

  &__inner {
    position: relative;
    height: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: theme('colors.brand.yellow');
    overflow-y: auto;

    @media (min-width: $ss-breakpoint-ip6) {
      width: 100%;
    }

    @media (min-width: $ss-breakpoint-sm) {
      width: 100%;
    }
  }

  &__header {
    position: absolute;
    z-index: $ss-zindex-10;
    top: 0;
    left: 0;
    background-color: $white;
    width: 280px;

    @media (min-width: $ss-breakpoint-ip6) {
      width: 320px;
    }

    @media (min-width: $ss-breakpoint-sm) {
      width: calc(100% - 54px);
    }

    .nav-tabs {
      .nav-item {
        width: 50%;
        text-align: center;
        position: relative;

        a {
          color: $ss-grey-light-color;
          text-transform: uppercase;
        }

        &:first-child {
          &:after {
            content: ' ';
            position: absolute;
            top: 10px;
            right: 0;
            height: 16px;
            width: 1px;
            background-color: $ss-border-color;
          }
        }
      }

      .nav-link.active,
      .nav-item.show .nav-link {
        border-color: transparent;
        border-bottom-color: $ss-black-color;
        color: $ss-black-color;
      }
    }
  }

  &__footer {
    position: absolute;
    z-index: $ss-zindex-10;
    bottom: 0;
    left: 0;
    background-color: $white;
    width: 280px;

    @media (min-width: $ss-breakpoint-ip6) {
      width: 320px;
    }

    @media (min-width: $ss-breakpoint-sm) {
      width: calc(100% - 54px);
    }

    &-text {
      font-weight: $ss-font-weight-bold;
      font-size: $ss-font-size-sm;
      margin-right: 4px;

      @media (min-width: $ss-breakpoint-ip6) {
        font-size: $ss-font-size-base;
        margin-right: 8px;
      }
    }

    span {
      white-space: nowrap;
    }

    select {
      width: 80px; border: 1px solid $ss-border-color;
    }
  }

  &__media {
    display: flex;
    background: $ss-grey-lightest-color;
    border-radius: $ss-border-radius-base;
    align-items: center;

    img {
      flex-shrink: 0;
      object-fit: cover;
      width: 110px;
      height: 70px;
      border-top-right-radius: $ss-border-radius-base;
      border-bottom-right-radius: $ss-border-radius-base;
    }
  }

  &__media--white {
    background: $white;
    border: 1px solid $ss-border-color;
  }

  &__media--reverse {
    img {
      width: 160px;
      height: 100px;
      border-top-right-radius: 0;
      border-top-left-radius: $ss-border-radius-base;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: $ss-border-radius-base;
    }
  }

  &__media + &__media {
    margin-top: 16px;
  }

  &__media-title {
    flex-grow: 1;
    text-align: center;
    font-weight: $ss-font-weight-bold;
    color: $ss-black-color;
  }
}

.sidenav.sidenav-rtl {
  left: auto;
  right: 0;
  top: 0;
  transform: translateX(100%);
  height: 100%;
  z-index: theme('zIndex.1051');

  &.active {
    transform: translateX(0);
    background-color: rgba($ss-black-color, 0.5);
  }

  .sidenav__btn {
    position: absolute;
    top: 16px;
    left: 8px;

    @media (min-width: $ss-breakpoint-ip6) {
      left: 12px;
    }
  }

  .sidenav__content {
    width: 300px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.corp-nav-link {
  font-size: $ss-font-size-base;
  color: $ss-black-color;

  &.active,
  &:hover {
    color: $ss-cyan-color;
  }

  &.active {
    font-weight: $ss-font-weight-bold;
  }
}

.corp-nav-link + .corp-nav-link {
  margin-left: 32px;
}

.navbar.navbar-primary.corp-sub-nav {
  top: 66px;
  height: 48px;

  @media (min-width: $ss-breakpoint-md) {
    display: none;
  }
}
