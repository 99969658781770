.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
}

.btn {
  font-family: theme('fontFamily.styleSans');
  font-weight: theme('fontWeight.semibold');
  border-radius: theme('borderRadius.4xl');
  border: 0;
  padding: 8px 24px;
  line-height: 1;
  text-transform: lowercase;
  outline: none;

  &:not(:disabled):not(.disabled) {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  @media (min-width: theme('screens.md')) {
    padding: 12px 32px;
  }

  &.btn-outline {
    border: 1px solid theme('colors.brand.navy');
    color: theme('colors.brand.navy');
    background-color: transparent;
  }

  &.btn-dots {
    border-radius: 50% !important;
    padding: 8px;
    line-height: 12px;

    &--cyan {
      color: $ss-cyan-color;
      border-color: $ss-cyan-color;
    }
  }

  &--white {
    border-color: theme('colors.brand.navy');
    color: theme('colors.brand.navy');
    background-color: $white;

    @media (hover: hover) {
      &:hover {
        background-color: theme('colors.brand.navy');
        color: theme('colors.brand.green');
      }
    }
  }

  &--white-corp {
    border-radius: $ss-border-radius-lg;
    border-width: 1px;
    font-weight: $ss-font-weight-bold;

    @media (min-width: $ss-breakpoint-md) {
      width: 150px;
    }

    &:hover {
      background-color: $ss-black-color;
      color: $white;
    }
  }

  &--outline-white {
    border-color: $white;
    color: $white;
    background-color: transparent;

    &:hover {
      color: $white;
    }
  }

  &.btn-black {
    border-color: $ss-black-color;
    color: $white;
    background-color: $ss-black-color;

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.btn-primary {
    background-color: theme('colors.orange.DEFAULT');
    color: $white;

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &:active {
      color: $white;
      background-color: theme('colors.orange.DEFAULT');
    }
  }

  &.btn-secondary {
    background-color: theme('colors.brand.green');
    color: theme('colors.brand.navy');

    &:active {
      color: theme('colors.brand.navy');
    }
  }

  &.btn-warning {
    background-color: $ss-yellow-color;
    color: $white;

    &:not(:disabled):not(.disabled):active,
    &:active,
    &:focus {
      background-color: $ss-yellow-color;
      color: $white;
    }
  }

  &.btn-outline-primary {
    color: theme('colors.orange.DEFAULT');
    border: 1px solid theme('colors.orange.DEFAULT');

    &:active,
    &:hover {
      color: theme('colors.orange.DEFAULT');
      border-color: theme('colors.orange.DEFAULT');
      background-color: theme('colors.white');
    }

    &.disabled,
    &:disabled {
      color: $ss-grey-lighter-color;
      border-color: $ss-border-color;
      &:hover {
        color: $ss-grey-lighter-color;
      }
    }
  }

  &.btn-outline-secondary {
    border-color: $ss-grey-light-color;
    color: $ss-grey-light-color;

    &:active,
    &:hover {
      background-color: $ss-grey-light-color;
      color: $white;
    }
  }

  &.btn-purple {
    background-color: $ss-purple-color;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.btn-outline-purple {
    border-color: $ss-purple-color;
    color: $ss-purple-color;

    &:active,
    &:hover {
      background-color: $ss-purple-color;
      color: $white;
    }
  }

  &.btn-operator {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $ss-cyan-color;
    color: $ss-cyan-color;
    line-height: 19px;
    outline: none;
    box-shadow: none;

    svg {
      color: $ss-cyan-color;
    }

    &:disabled {
      border-color: $ss-grey-light-color;

      svg {
        color: $ss-grey-light-color;
      }
    }
  }
}

.btn-chat {
  position: fixed;
  z-index: 9999;
  right: 8px;
  bottom: 86px;
  background: $white;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
  -webkit-box-pack: center;
  color: $ss-black-color;

  @media (min-width: $ss-breakpoint-md) {
    right: 8px;
    bottom: 16px;
  }

  .spinner-border {
    display: none;
  }

  &.loading {
    svg {
      display: none;
    }

    .spinner-border {
      display: inline-block;
    }
  }
}

.btn-whatsapp {
  position: fixed;
  z-index: 9999;
  background-color: $whatsapp-color;
  border-radius: 9999px;
  width: 64px;
  height: 64px;
  bottom: 16px;
  right: 16px;
  display: block;
  text-align: center;
  padding: 16px 0;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    color: $white;
  }
}
