.alert {
  border: 0;
  border-radius: 0;
  font-weight: $ss-font-weight-bold;
  text-align: center;
  z-index: 9999;
  position: fixed;
  top: 72px;
  width: 100%;

  svg {
    stroke-width: 2px;
  }

  .close {
    opacity: 1;
  }
}

.alert-success {
  background-color: theme('colors.brand.yellow');
  color: theme('colors.brand.navy');
}

.alert-danger {
  background-color: theme('colors.brand.yellow');
  color: theme('colors.red.DEFAULT');
}
