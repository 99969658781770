.timeline {
  &__item {
    display: flex;
    position: relative;

    &::before {
      background: $ss-grey-color;
      content: '';
      height: calc(100% - 16px);
      left: 7px;
      position: absolute;
      top: 19px;
      width: 2px;
    }
  }

  &__left {
    flex: 0 0 auto;
    padding-top: 3px;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: 15px;
  }

  &__content {
    flex: 1 1 auto;
    padding-left: 15px;
    padding-bottom: 30px;

    @media (max-width: $ss-breakpoint-md) {
      .media {
        flex-wrap: wrap;
      }
    }

    .media {
      img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        margin-right: 16px;

        @media (min-width: $ss-breakpoint-md) {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  &__icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 16px;
    height: 16px;
  }
}
