.booking-form {
  &__title {
    font-size: $ss-font-size-xl;
    font-weight: $font-weight-bold;
    line-height: 1;
    padding-bottom: 10px;
    margin-bottom: 16px;
    border-bottom: 1px solid $ss-border-color;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: $ss-font-size-sm;
  }

  &__item-price {
    flex-shrink: 0;
    width: 162px;
    text-align: right;

    &--auto {
      width: auto;
    }
  }

  .form-control {
    font-size: $ss-font-size-sm;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: $white;
  }

  .form-group {
    margin-bottom: 10px;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input-group-text {
    border: 1px solid theme('colors.grey.DEFAULT');
    background-color: $white;
    border-radius: theme('borderRadius.lg');
    width: 44px;

    &--middle {
      width: 36px;
      padding-left: 0;
      padding-right: 12px;
    }
  }

  .form-control {
    color: theme('colors.grey.DEFAULT');
    border: 1px solid theme('colors.grey.DEFAULT');
    border-radius: theme('borderRadius.lg');
    appearance: none;
    font-size: $ss-font-size-sm;
    background-color: $white;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    height: 40px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;


    &::placeholder {
      color: theme('colors.grey.DEFAULT');
      opacity: 1;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: theme('colors.grey.DEFAULT');
      opacity: 1;
    }

    &.cyan {
      color: $ss-cyan-color !important;
    }
  }

  .input-group {
    cursor: pointer;

    > .input-group-prepend:not(:first-child) > .input-group-text {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    svg {
      color: $ss-grey-color;
    }

    select::-ms-expand {
      display: none;
    }

    &:hover {
      .input-group-text,
      .custom-select,
      .form-control {
        border-color: theme('colors.orange.DEFAULT');
      }
    }

    &.error {
      .input-group-text,
      .custom-select,
      .form-control {
        border-color: $ss-red-color;
      }
    }

    &.selected {
      .input-group-text,
      .custom-select,
      .form-control {
        border-color: theme('colors.brand.navy');
        color: theme('colors.brand.navy');
      }

      svg {
        color: theme('colors.brand.navy');
      }
    }

    &.selected-secondary {
      .input-group-text,
      .custom-select,
      .form-control {
        border-color: theme('colors.brand.navy');
        color: theme('colors.brand.navy');
      }

      .input-group-prepend svg {
        color: theme('colors.brand.navy');
      }
    }

    &.input-group-rtb:hover {
      .input-group-text,
      .custom-select,
      .form-control {
        border-color: theme('colors.brand.navy');
      }
    }

    &.input-group-disabled {
      .input-group-text,
      .custom-select,
      .form-control {
        color: $ss-black-color;
        background: $ss-grey-lightest-color;
        border-color: $ss-border-color;
      }

      svg {
        color: $ss-black-color;
      }
    }
  }

  &__datepicker-note {
    width: 316px; // datepicker width
    margin: 16px auto 8px;
    padding-left: 25px;
    text-align: left;
  }

  &__popup {
    cursor: default;
    position: absolute;
    z-index: $ss-zindex-1200;
    width: 100%;
    background-color: $white;
    top: calc(100% + 4px);
    padding: 8px 0;
    border: 1px solid $ss-border-color;
  }

  &__popup-item {
    padding: 8px 16px;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: $ss-cyan-lighter-color;
    }
  }
}

.booking-bottom-bar {
  display: none;
  padding: 12px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: theme('colors.brand.yellow');
  width: 100%;
  font-size: $ss-font-size-base;
  z-index: $ss-zindex-90;

  &.active {
    display: flex;
  }

  .btn {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.booking-timeslot {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $ss-zindex-2000;

  &__mask {
    background: rgba($ss-black-color, 0.6);
    width: 100%;
    height: 100%;
  }

  &__selection {
    position: fixed;
    width: 176px;
    background: $white;
    border-radius: $ss-border-radius-base;
    top: 0;
    left: 0;
  }

  &__inner {
    position: relative;
  }

  &__list {
    padding: 8px 0;
    max-height: 162px;
    overflow-y: auto;
  }

  &__triangle {
    top: -12px;
    left: 80px;
    position: absolute;
    @include triangle('top', $white, 8px);
  }

  &__item {
    text-align: center;
    font-weight: $ss-font-weight-bold;
    cursor: pointer;
    padding: 6px 0;

    &:hover {
      background: $ss-cyan-light-color;
    }
  }
}

.booking-form__modal {
  z-index: $ss-zindex-1200;
}
