.stripe-card-input {
  &.StripeElement {
    box-sizing: border-box;
    padding: 11px 12px;
    border: 1px solid #d9d9d9;
    box-shadow: none;
    border-radius: 8px;
    background-color: white;
  }

  &.StripeElement--invalid {
    color: theme('colors.red.DEFAULT');
    border-color: theme('colors.red.DEFAULT');
    box-shadow: none;
  }

  &.StripeElement--focus {
    outline: none;
    border-color: #d9d9d9;
    box-shadow: none;
  }
}
