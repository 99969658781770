.badge {
  font-size: $ss-font-size-xs;
  padding: 8px;
  border-radius: $ss-border-radius-base;
}

.badge-primary {
  color: $white;
}

.badge-blog {
  background-color: $ss-grey-lightest-color;
  padding: 8px 16px;
  font-size: $ss-font-size-sm;
  font-weight: $ss-font-weight-base;
  border-radius: $ss-border-radius-base;
  margin-right: 16px;
  margin-bottom: 8px;
  cursor: pointer;

  a {
    color: $ss-grey-color;

    &:hover {
      color: $ss-grey-color;
    }
  }

  &--arrow {
    cursor: initial;
  }
}

.badge-discount {
  display: inline-block;
  border-radius: $ss-border-radius-base;
  border: 1px solid $ss-border-color;
  padding: 4px;
  color: $ss-grey-color;
  font-size: $ss-font-size-xs;

  svg {
    color: $ss-grey-color;
  }
}

.badge-red {
  color: $white;
  background-color: $ss-red-color;
}

.badge-green-dark {
  color: $white;
  background-color: $ss-green-dark-color;
}

.badge-teal {
  color: $white;
  background-color: $ss-teal-color;
}

.tag-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $ss-border-color;
  border-radius: $ss-border-radius-base;
  width: calc(100%/3 - 16px);
  height: 68px;
  padding: 8px;
  font-size: $ss-font-size-xxs;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: $ss-breakpoint-md) {
    width: calc(25% - 16px);
    height: 76px;
  }

  img {
    margin-bottom: 4px;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}
