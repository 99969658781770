.toast-preview {
  z-index: $ss-zindex-100;
  position: fixed;
  bottom: 105px;
  background-color: $ss-yellow-color;
  color: $ss-black-color;
  font-weight: $ss-font-weight-bold;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 0;
  width: 100%;
}

.toast-warning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $ss-border-radius-base;
  border: 1px solid $ss-yellow-color;
  background-color: $ss-yellow-lightest-color;
  padding: 8px;
  font-size: $ss-font-size-xs;
}
