.flatpickr-calendar {
  .flatpickr-current-month input.cur-year,
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    font-size: $ss-font-size-base;
  }
}

.flatpickr-custom {
  &.flatpickr-calendar,
  .flatpickr-calendar {
    padding: 5px;
    width: 316px;
    box-shadow: none;
    &:before,
    &:after {
      content: none;
    }
    &.static.open {
      left: -56px;
      @media (min-width: 375px) {
        left: -45px;
      }
      @media (min-width: $ss-breakpoint-md) {
        left: -30px;
      }
    }
    @media (max-width: 359px) {
      left: -15px;
    }
  }
  .flatpickr-wrapper {
    width: 100%;
    flex-grow: 1;
    .form-control {
      border-radius: 0;
      border-left: 0;
    }
  }
  .flatpickr-day {
    max-width: 39px;
    height: 36px;
    line-height: 36px;
    margin: 3px 2px;
    border-radius: 2px;
    color: $ss-cyan-color;
    border: 1px solid $ss-cyan-color;

    &.endRange {
      color: $white !important;
      background: $ss-cyan-color !important;
      border-top-right-radius: 8px !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 0 !important;


      &:hover {
        border-color: theme('colors.cyan.dark') !important;
      }
    }

    &.startRange:hover {
      border-color: theme('colors.cyan.dark') !important;
    }

    &.inRange {
      color: $white !important;
    }

    &.inOneDay {
      border-color: $ss-black-color;
      border-radius: theme('borderRadius.lg') !important;
    }

    &.selected {
      background-color: $ss-cyan-color;
      color: $white;
    }

    &:focus,
    &:hover {
      background-color: $ss-cyan-dark-color;
      color: $white;
    }
    &.selected-checkout,
    &.seq-selected {
      background-color: $ss-cyan-color;
      border-color: $ss-cyan-color;
      color: $white;
    }
    &.selected-luck-checkout,
    &.secondary-seq-selected {
      background-color: $ss-grey-light-color;
      border-color: $ss-grey-light-color;
      color: $white;
      &.first-luck {
        background-color: $ss-black-color;
        color: $white;
      }
    }
    &.flatpickr-disabled:hover {
      cursor: not-allowed;
      color: $ss-grey-lightest-color;
      background-color: $white;
      border-color: $ss-grey-lightest-color;
    }
    &.inRange {
      box-shadow: none;
      background: $ss-cyan-color;
      border-color: $ss-cyan-color;
      color: $white;
    }
    &.nextMonthDay,
    &.prevMonthDay {
      visibility: hidden;
      height: 0;
    }
    &.flatpickr-day-secondary {
      color: $ss-grey-light-color;
      border: 1px solid $ss-grey-light-color;
      text-decoration: line-through;
      &.selected,
      &:focus,
      &:hover {
        background-color: $ss-black-color;
        color: $white;
        &.invalid-checkout,
        &.disabled-checkout {
          background-color: $white;
          color: $ss-grey-lightest-color;
        }
      }
      &.selected-checkout,
      &.seq-selected {
        background-color: $ss-cyan-color;
        border-color: $ss-cyan-color;
        color: $white;
      }
      &.selected-luck-checkout,
      &.secondary-seq-selected {
        background-color: $ss-grey-light-color;
        border-color: $ss-grey-light-color;
        color: $white;
        &.first-luck {
          background-color: $ss-black-color;
          color: $white;
        }
      }
      &.inRange {
        background: $ss-grey-light-color;
        border-color: $ss-grey-light-color;
        color: $white;
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: $white;
          color: $ss-grey-light-color;
          border: 1px solid $ss-grey-light-color;
        }
      }
    }
    &.disabled-checkin {
      color: $ss-grey-light-color;
      border: 1px solid $ss-grey-light-color;
      text-decoration: line-through;
      &.selected,
      &:focus,
      &:hover {
        background-color: $ss-black-color;
        color: $white;
      }

      &.seq-selected {
        color: $white;
        border-color: $ss-cyan-color;
      }

      &.secondary-seq-selected {
        color: $white;
      }

      &.selected-checkout {
        background-color: $ss-cyan-color;
        border-color: $ss-cyan-color;
        color: $white;
      }
      &.selected-luck-checkout {
        background-color: $ss-grey-light-color;
        border-color: $ss-grey-light-color;
        color: $white;
        &.first-luck {
          background-color: $ss-black-color;
          color: $white;
        }
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: $white;
          color: $ss-grey-light-color;
          border: 1px solid $ss-grey-light-color;
        }
      }
    }
    &.booked-checkout {
      color: $ss-grey-light-color;
      border: 1px solid $ss-grey-light-color;
      text-decoration: line-through;
      &:focus,
      &:hover {
        background-color: $ss-black-color;
        color: $white;
      }
      &.selected-luck-checkout {
        background-color: $ss-grey-light-color;
        border-color: $ss-grey-light-color;
        color: $white;
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: $white;
          color: $ss-grey-light-color;
          border: 1px solid $ss-grey-light-color;
        }
      }
    }
    &.enabled-checkout {
      color: $ss-cyan-color;
      border: 1px solid $ss-cyan-color;
      text-decoration: none;
      &.seq-selected,
      &.selected-checkout,
      &:focus,
      &:hover {
        background-color: $ss-cyan-color;
        color: $white;
      }
      &.selected-luck-checkout {
        background-color: $ss-grey-light-color;
        border-color: $ss-grey-light-color;
        color: $white;
      }
    }
    .flatpickr-day-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    .flatpickr-day-date {
      width: 100%;
      margin-bottom: 2px;
      line-height: 1;
      font-size: $ss-font-size-sm;
      @media (min-width: $ss-breakpoint-md) {
        margin-bottom: 4px;
      }
    }
    .flatpickr-day-price {
      line-height: 1;
      width: 100%;
      font-size: $ss-font-size-xxs;
      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-xs;
      }
    }
  }
  .flatpickr-disabled {
    color: $ss-grey-lightest-color;
    border-color: $ss-grey-lightest-color;
    &.invalid-checkout,
    &.disabled-checkout {
      color: $ss-grey-lighter-color;
      border-color: $ss-grey-lighter-color;
    }
  }
  .flatpickr-monthDropdown-months {
    appearance: none;
    cursor: default;
    font-size: $ss-font-size-base;
    font-weight: $font-weight-bold;
    color: $ss-black-color;
    opacity: 1;
  }
  .flatpickr-current-month {
    .flatpickr-monthDropdown-months:hover {
      background: inherit;
    }
    input.cur-year[disabled] {
      color: $body-color;
      font-size: $ss-font-size-base;
      font-weight: $font-weight-bold;
    }
  }
  .numInputWrapper {
    &:hover {
      background: inherit;
    }
  }
  .flatpickr-months {
    .flatpickr-prev-month.flatpickr-prev-month {
      left: 8px;
    }
    .flatpickr-next-month.flatpickr-next-month {
      right: 8px;
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      border: 1px solid $ss-grey-light-color;
      height: 30px;
      width: 30px;
      padding: 0;
      top: 8px;
      line-height: 28px;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      color: $body-color;
      fill: $body-color;
      &:hover {
        border-color: $ss-cyan-color;
        svg {
          fill: $ss-cyan-color;
        }
      }
    }
    .flatpickr-prev-month.flatpickr-disabled,
    .flatpickr-next-month.flatpickr-disabled {
      display: none;
    }
  }
  .flatpickr-weekdays {
    margin: 8px 0 4px;
  }
  .flatpickr-weekday {
    font-size: $ss-font-size-sm;
    color: $body-color;
  }
  &.flatpickr-custom--md {
    @media (min-width: $ss-breakpoint-ip6) {
      position: relative;
      width: 341px;
      .flatpickr-days {
        width: 333px;
      }
      .flatpickr-calendar {
        width: 341px;
      }
      .dayContainer {
        width: 333px;
        min-width: 333px;
        max-width: 333px;
      }
      .flatpickr-weekday {
        max-width: 50px;
        margin: 0 1px;
      }
      .flatpickr-day {
        max-width: 44px;
        height: 40px;
        margin: 1px;
        line-height: 1;
        border-radius: theme('borderRadius.lg');
      }
    }
    @media (min-width: $ss-breakpoint-md) {
      position: relative;
      width: 406px;
      .flatpickr-days {
        width: 394px;
      }
      .flatpickr-calendar {
        width: 406px;
      }
      .dayContainer {
        width: 394px;
        min-width: 394px;
        max-width: 394px;
      }
      .flatpickr-weekday {
        max-width: 54px;
        margin: 0 1px;
      }
      .flatpickr-day {
        max-width: 53px;
        height: 48px;
        margin: 1px;
        line-height: 1;
        border-radius: theme('borderRadius.lg');
      }
    }
  }
  &.flatpickr-custom--xl {
    @media (min-width: $ss-breakpoint-ip6) {
      position: relative;
      width: 341px;
      .flatpickr-days {
        width: 333px;
      }
      .flatpickr-calendar {
        width: 341px;
      }
      .dayContainer {
        width: 333px;
        min-width: 333px;
        max-width: 333px;
      }
      .flatpickr-weekday {
        max-width: 50px;
        margin: 0 1px;
      }
      .flatpickr-day {
        max-width: 44px;
        height: 40px;
        margin: 1px;
        line-height: 1;
        border-radius: theme('borderRadius.lg');
      }
    }
    @media (min-width: $ss-breakpoint-md) {
      position: relative;
      width: 488px;
      .flatpickr-days {
        width: 480px;
      }
      .flatpickr-calendar {
        width: 488px;
      }
      .dayContainer {
        width: 480px;
        min-width: 480px;
        max-width: 480px;
      }
      .flatpickr-weekday {
        max-width: 68px;
        margin: 0 1px;
      }
      .flatpickr-day {
        max-width: 62px;
        height: 56px;
        margin: 1px;
        line-height: 1;
        border-radius: theme('borderRadius.lg');
      }
    }
  }

  .numInputWrapper {
    width: 54px;
  }
  .flatpickr-current-month input.cur-year {
    font-weight: $ss-font-weight-bold;
  }
}

.flatpickr-custom {
  &.flatpickr-calendar {
    padding: 16px;
    width: 341px;
    border: 1px solid $ss-border-color;
  }

  &.flatpickr-header-date {
    .flatpickr-wrapper {
      display: flex;
      justify-content: center;
    }

    .flatpickr-day {
      border-radius: theme('borderRadius.lg');
    }
  }

  &.flatpickr-with-selector {
    .flatpickr-monthDropdown-months {
      cursor: pointer;
      appearance: menulist;
    }

    .numInputWrapper {
      margin-left: 4px;
    }

    .numInputWrapper span {
      right: -8px;
      border: 0;
      opacity: 1;
    }
  }
}

.flatpickr-brand {
  .flatpickr-calendar {
    background-color: transparent;
    padding: 4px 0;
    width: 336px;
    box-shadow: none;
    &:before,
    &:after {
      content: none;
    }
    &.static.open {
      left: -56px;
      @media (min-width: 375px) {
        left: -45px;
      }
      @media (min-width: $ss-breakpoint-md) {
        left: -30px;
      }
    }
    @media (max-width: 359px) {
      left: -15px;
    }

    @media(min-width: theme('screens.md')) {
      width: 462px;
    }
  }
  .flatpickr-days {
    width: 336px;

    @media(min-width: theme('screens.md')) {
      width: 462px;
    }
  }

  .dayContainer {
    width: 336px;
    min-width: 336px;
    max-width: 336px;

    @media(min-width: theme('screens.md')) {
      width: 462px;
      min-width: 462px;
      max-width: 462px;
    }
  }

  .flatpickr-wrapper {
    width: 336px;
    flex-grow: 1;
    display: block;
    margin: 0 auto;

    @media(min-width: theme('screens.md')) {
      width: 462px;
      margin: 0;
    }

    .form-control {
      border-radius: 0;
      border-left: 0;
    }
  }
  .flatpickr-day {
    max-width: 44px;
    height: 44px;
    line-height: 44px;
    margin: 3px 2px;
    border-radius: theme('borderRadius.lg');
    color: theme('colors.brand.navy');
    border: 1px solid theme('colors.brand.navy');
    box-shadow: none !important;

    @media(min-width: theme('screens.md')) {
      max-width: 62px;
      height: 62px;
      line-height: 62px;
    }

    &.startRange,
    &.endRange {
      color: theme('colors.brand.navy') !important;
      background: theme('colors.brand.green') !important;
      border-radius: theme('borderRadius.lg') !important;

      &:hover {
        border-color: theme('colors.brand.green') !important;
        opacity: 0.9;
      }
    }

    &.inRange {
      color: theme('colors.brand.navy') !important;
    }

    &.inOneDay {
      border-color: theme('colors.brand.navy');
      border-radius: theme('borderRadius.lg') !important;
    }

    &.selected {
      background-color: theme('colors.brand.green');
      color: theme('colors.brand.navy');

      &:hover {
        border-color: theme('colors.brand.navy');
      }
    }

    &:focus,
    &:hover {
      background-color: theme('colors.brand.green');
      color: theme('colors.brand.navy');
    }
    &.selected-checkout,
    &.seq-selected {
      background-color: theme('colors.brand.green');
      border-color: theme('colors.brand.navy');
      color: theme('colors.brand.navy');
    }
    &.selected-luck-checkout,
    &.secondary-seq-selected {
      background-color: theme('colors.grey.light');
      border-color: theme('colors.grey.light');
      color: $white;
      &.first-luck {
        background-color: theme('colors.brand.navy');
        color: $white;
      }
    }
    &.flatpickr-disabled:hover {
      cursor: not-allowed;
      color: theme('colors.grey.lighter');
      background-color: theme('colors.brand.yellow');
      border-color: theme('colors.grey.lighter');
    }
    &.inRange {
      box-shadow: none;
      background: theme('colors.brand.green');
      border-color: theme('colors.brand.navy');
      color: theme('colors.brand.navy');
    }
    &.nextMonthDay,
    &.prevMonthDay {
      visibility: hidden;
      height: 0;
    }
    &.flatpickr-day-secondary {
      color: theme('colors.grey.lighter');
      border: 1px solid theme('colors.grey.lighter');
      text-decoration: line-through;
      &.selected,
      &:focus,
      &:hover {
        background-color: theme('colors.brand.navy');
        color: $white;
        &.invalid-checkout,
        &.disabled-checkout {
          background-color: theme('colors.brand.yellow');
          color: theme('colors.grey.lightest');
        }
      }
      &.selected-checkout,
      &.seq-selected {
        background-color: theme('colors.brand.green');
        border-color: theme('colors.brand.navy');
        color: theme('colors.brand.navy');
      }
      &.selected-luck-checkout,
      &.secondary-seq-selected {
        background-color: theme('colors.grey.light');
        border-color: theme('colors.grey.light');
        color: $white;
        &.first-luck {
          background-color: theme('colors.brand.navy');
          color: $white;
        }
      }
      &.inRange {
        background: theme('colors.grey.light');
        border-color: theme('colors.grey.light');
        color: $white;
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: theme('colors.brand.yellow');
          color: theme('colors.grey.light');
          border: 1px solid theme('colors.grey.light');
        }
      }
    }
    &.disabled-checkin {
      color: theme('colors.grey.light');
      border: 1px solid theme('colors.grey.light');
      text-decoration: line-through;
      &.selected,
      &:focus,
      &:hover {
        background-color: theme('colors.brand.navy');
        color: $white;
      }

      &.seq-selected {
        color: $white;
        border-color: theme('colors.brand.navy');
      }

      &.secondary-seq-selected {
        color: $white;
      }

      &.selected-checkout {
        background-color: theme('colors.brand.green');
        border-color: theme('colors.brand.navy');
        color: theme('colors.brand.navy');
      }
      &.selected-luck-checkout {
        background-color: theme('colors.grey.light');
        border-color: theme('colors.grey.light');
        color: $white;
        &.first-luck {
          background-color: theme('colors.brand.navy');
          color: $white;
        }
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: theme('colors.brand.yellow');
          color: theme('colors.grey.light');
          border: 1px solid theme('colors.grey.light');
        }
      }
    }
    &.booked-checkout {
      color: theme('colors.grey.light');
      border: 1px solid theme('colors.grey.light');
      text-decoration: line-through;
      &:focus,
      &:hover {
        background-color: theme('colors.brand.navy');
        color: $white;
      }
      &.selected-luck-checkout {
        background-color: theme('colors.grey.light');
        border-color: theme('colors.grey.light');
        color: $white;
      }

      &.notAllowed {
        &.selected,
        &:focus,
        &:hover {
          background-color: $white;
          color: theme('colors.grey.light');
          border: 1px solid theme('colors.grey.light');
        }
      }
    }
    &.enabled-checkout {
      color: theme('colors.brand.navy');
      border: 1px solid theme('colors.brand.navy');
      text-decoration: none;
      &.seq-selected,
      &.selected-checkout,
      &:focus,
      &:hover {
        background-color: theme('colors.brand.green');
        color: theme('colors.brand.navy');
      }
      &.selected-luck-checkout {
        background-color: theme('colors.grey.light');
        border-color: theme('colors.grey.light');
        color: $white;
      }
    }
    .flatpickr-day-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    .flatpickr-day-date {
      width: 100%;
      margin-bottom: 2px;
      line-height: 1;
      font-size: $ss-font-size-sm;
      @media (min-width: $ss-breakpoint-md) {
        margin-bottom: 4px;
      }
    }
    .flatpickr-day-price {
      line-height: 1;
      width: 100%;
      font-size: $ss-font-size-xxs;
      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-xs;
      }
    }
  }
  .flatpickr-disabled {
    color: theme('colors.grey.lighter');
    border-color: theme('colors.grey.lighter');
    &.invalid-checkout,
    &.disabled-checkout {
      color: theme('colors.grey.lighter');
      border-color: theme('colors.grey.lighter');
    }
  }
  .flatpickr-monthDropdown-months {
    appearance: none;
    cursor: default;
    font-size: $ss-font-size-base;
    font-weight: $font-weight-bold;
    color: theme('colors.brand.navy');
    opacity: 1;
  }
  .flatpickr-current-month {
    .flatpickr-monthDropdown-months:hover {
      background: inherit;
    }
    input.cur-year[disabled] {
      opacity: 1;
      color: theme('colors.brand.navy');
      font-size: $ss-font-size-base;
      font-weight: $font-weight-bold;
    }
  }
  .numInputWrapper {
    &:hover {
      background: inherit;
    }
  }
  .flatpickr-months {
    .flatpickr-prev-month.flatpickr-prev-month {
      left: 40px;

      @media(min-width: theme('screens.md')) {
        left: 100px;
      }
    }
    .flatpickr-next-month.flatpickr-next-month {
      right: 40px;

      @media(min-width: theme('screens.md')) {
        right: 100px;
      }
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      padding: 0;
      top: 8px;
      color: theme('colors.brand.navy');
      fill: theme('colors.brand.navy');
      height: 24px;

      svg {
        vertical-align: middle;
        width: 24px;
        height: 24px;
      }

      &:hover {
        border-color: theme('colors.brand.navy');
        svg {
          fill: theme('colors.brand.navy');
        }
      }
    }
    .flatpickr-prev-month.flatpickr-disabled,
    .flatpickr-next-month.flatpickr-disabled {
      display: block;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .flatpickr-weekdays {
    margin: 8px 0 4px;
  }
  .flatpickr-weekday {
    font-size: $ss-font-size-sm;
    color: theme('colors.brand.navy');
  }

  .numInputWrapper {
    width: 54px;
  }
  .flatpickr-current-month input.cur-year {
    color: theme('colors.brand.navy');
    font-weight: $ss-font-weight-bold;
  }

  .numInputWrapper span {
    right: -8px;
    border: 0;
  }

  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: theme('colors.brand.navy');
  }

  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: theme('colors.brand.navy');
  }

  &.flatpickr-header-date {
    .flatpickr-wrapper {
      display: flex;
      justify-content: center;
    }

    .flatpickr-day {
      border-radius: theme('borderRadius.lg');
    }
  }

  &.flatpickr-brand-sm {
    .flatpickr-wrapper {
      width: 336px;
    }

    .flatpickr-days {
      width: 336px;
    }
    .flatpickr-calendar {
      width: 336px;
    }
    .dayContainer {
      width: 336px;
      min-width: 336px;
      max-width: 336px;
    }
    .flatpickr-weekday {
      max-width: 50px;
      margin: 0 1px;
    }
    .flatpickr-day {
      max-width: 44px;
      height: 44px;
      line-height: 44px;
    }

    .flatpickr-months {
      .flatpickr-prev-month.flatpickr-prev-month {
        left: 60px;
      }
      .flatpickr-next-month.flatpickr-next-month {
        right: 60px;
      }
    }
  }
}
