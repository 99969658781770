.gift-banner {
  text-align: center;
  width: 100%;
  height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  @media (min-width: $ss-breakpoint-md) {
    height: 550px;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  &.gift-banner-card {
    @include img-as-bg();
    background-image: url('/images/gift_card/gift_card.png');
  }

  &.gift-banner-exp {
    @include img-as-bg();
    background-image: url('/images/gift_card/gift_experience.jpg');
  }
}
