.hero-country {
  @include img-as-bg;
  width: 100%;
  height: 320px;
  overflow: hidden;

  @media (min-width: $ss-breakpoint-md) {
    height: 400px;
  }

  &--sm {
    height: 250px;
  }

  &--lg {
    height: 510px;
  }

  &__content {
    background: rgba($ss-true-black-color, 0.5);
    height: 100%;

    @media (min-width: $ss-breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      height: 100%;

      @media (min-width: $ss-breakpoint-md) {
        height: auto;
      }
    }

    .row {
      height: 100%;

      @media (min-width: $ss-breakpoint-md) {
        height: auto;
      }
    }
  }

  &__center {
    align-self: center;
  }

  &__right {
    margin-top: 32px;

    @media (min-width: $ss-breakpoint-lg) {
      margin-top: 0;
    }
  }

  &__dash {
    width: 32px;
    border-bottom: 4px solid $white;
    margin-bottom: 24px;
  }

  &__type {
    color: $white;
    font-weight: $ss-font-weight-bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: $ss-font-size-sm;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
      margin-bottom: 24px;
      display: block;
    }

    &--pc {
      display: none;
    }

    &--normal {
      text-transform: none;
      font-size: $ss-font-size-lg;
      line-height: 1;
    }
  }

  &__title {
    line-height: 1;
    font-size: $ss-font-size-title-4;
    color: $white;
    font-weight: $ss-font-weight-bold;
    margin-bottom: 8px;

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 16px;
    }

    &--xl {
      line-height: 1;

      @media (min-width: $ss-breakpoint-md) {
        margin-bottom: 16px;
        font-size: 36px;
      }
    }
  }

  &__desc {
    color: $white;
    line-height: 1.4;
    margin-bottom: 16px;
    font-size: $ss-font-size-sm;
    text-align: center;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
      margin-bottom: 32px;
      text-align: left;
    }
  }

  &__btn {
    color: $white;
    font-size: $ss-font-size-base;
    font-weight: $ss-font-weight-bold;
    cursor: pointer;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
    }

    .btn {
      border: 0;
      text-decoration: underline;
      padding-left: 0;

      @media (min-width: $ss-breakpoint-md) {
        text-decoration: inherit;
        border: 1px solid $white;
        padding-left: 12px;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  &__link {
    font-size: $ss-font-size-base;
    color: $white;
    font-weight: $ss-font-weight-bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $white;
    }

    &--sm {
      font-size: $ss-font-size-sm;
    }
  }


  &__counter {
    flex: 0 0 auto;
    display: block;
    width: 52px;
    height: 48px;
    background-color: $white;
    border-radius: $ss-border-radius-base;
    line-height: 48px;
    text-align: center;
    font-size: $ss-font-size-lg;
    font-weight: $ss-font-weight-bold;
    margin-left: 4px;

    @media (min-width: $ss-breakpoint-md) {
      display: none;
    }
  }

  &__box {
    display: flex;

    @media (min-width: $ss-breakpoint-md) {
      background-color: inherit;
      border-radius: none;
      padding: 0;
    }
  }
}
