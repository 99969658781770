*, *:after, *:before {
  border: 0 solid;
}

@media print {
  button {
    display: none;
    text-indent: -9999px;
  }
}

body {
  font-family: theme('fontFamily.sans');
  font-weight: 400;
  color: theme('colors.brand.navy');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  line-height: 1.4;
  letter-spacing: 0.03em;

  &.filter-popup-open {
    overflow: hidden;
  }
}

b,
strong {
  font-weight: $ss-font-weight-bold;
}

.row {
  margin-left: -8px;
  margin-right: -8px;

  @media (min-width: $ss-breakpoint-md) {
    margin-left: -15px;
    margin-right: -15px;
  }

  &.row-narrow {
    margin-left: -8px;
    margin-right: -8px;
  }

  &.row-center {
    justify-content: center;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: $ss-breakpoint-md) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container {
  @media (min-width: $ss-breakpoint-sm) {
    max-width: 100%;
  }

  @media (min-width: $ss-breakpoint-md) {
    max-width: 100%;
  }

  @media (min-width: $ss-breakpoint-lg) {
    max-width: map-get($container-max-widths, "lg");
  }

  @media (min-width: $ss-breakpoint-xl) {
    max-width: map-get($container-max-widths, "xl");
  }
}

a {
  color: theme('colors.brand.navy');
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

select,
input {
  letter-spacing: 0.03em;
}

select::-ms-expand { display: block; }

strong,
b {
  font-weight: $ss-font-weight-bold;
}

mark {
  padding: 0;
  background: inherit;
  font-weight: theme('fontWeight.semibold');
  color: theme('colors.orange.DEFAULT');
}

.map-responsive {
  width: 100%;
  min-height: 100%;
  position: relative;

  @media (max-width: 999px) {
    height: 300px;
  }

  img {
    background-color: transparent;
  }
}

.map-marker {
  position: absolute;

  .map-marker-icon-day {
    position: relative;
    border-radius: 60px;
    min-width: 50px;
    width: auto;
    height: 30px;
    padding: 4px;
    cursor: pointer;
    color: theme('colors.white');
    background-color: theme('colors.brand.navy');
    font-family: 'Kobe';
    text-align: center;
    font-size: theme('fontSize.sm');
    font-weight: theme('fontWeight.semibold');
    font-size: theme('fontSize.sm');
    line-height: 24px;

    @media (min-width: $ss-breakpoint-md) {
      min-width: 70px;
      width: auto;
      height: 30px;
      padding: 4px 8px 4px 8px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      bottom: -22px;
      border: 10px solid transparent;
      border-top: 17px solid theme('colors.brand.navy');
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .map-marker-icon {
    position: relative;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: theme('colors.white');
    font-family: 'Kobe';
    background-color: theme('colors.brand.navy');
    text-align: center;
    font-weight: theme('fontWeight.semibold');
    font-size: theme('fontSize.sm');
    line-height: 24px;

    &:after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      bottom: -21px;
      left: 2px;
      border: 10px solid transparent;
      border-top: 17px solid theme('colors.brand.navy');
    }
  }

  &.active {
    .map-marker-icon {
      background-color: theme('colors.cyan.dark');

      &:after {
        border-top: 17px solid theme('colors.cyan.dark');
      }
    }
  }
}

@responsive {
  .custom-scrollbar{
    scrollbar-color: transparent transparent;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      display: initial;
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      scrollbar-color: theme('colors.grey.lighter') theme('colors.grey.most-lightest');

      &::-webkit-scrollbar-track {
        background-color: theme('colors.grey.lightest');
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: theme('colors.grey.lighter');
        border-radius: 8px;
      }
    }
  }

  .custom-scrollbar-no-hover {
    scrollbar-color: theme('colors.grey.lighter') theme('colors.grey.most-lightest');
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      display: initial;
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: theme('colors.grey.lightest');
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: theme('colors.grey.lighter');
      border-radius: 8px;
    }
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
