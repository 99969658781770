html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -10px;
}

.noUi-horizontal {
  height: 5px;
  box-shadow: none;

  .noUi-handle {
    width: 24px;
    height: 24px;
    top: -10px;
  }
}

.noUi-target {
  background: $ss-grey-light-color;
  border-radius: 4px;
  border: 0;
}

.noUi-connect {
  background: $ss-cyan-color;
}

.noUi-handle {
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  background-color: $ss-cyan-color;
  cursor: pointer;

  &:before,
  &:after {
    content: initial;
  }
}

.noUi-connect {
  background: $ss-cyan-color;
}
