.popover.popover--timepicker {
  .arrow {
    display: none;
  }
}

.timepicker {
  display: flex;
  align-items: start;

  &__input-container {
    position: relative;
    width: 100px;

    @media (min-width: $ss-breakpoint-md) {
      width: 120px;
    }
  }

  &__select {
    max-height: 200px;
    overflow-y: auto;
  }

  &__select-item {
    padding: 0 2px;

    &:hover {
      background-color: $ss-grey-lightest-color;
      cursor: pointer;
    }

    &.active {
      font-weight: $ss-font-weight-bold;
      color: $ss-cyan-color;
    }
  }

  &__btn-clear {
    position: absolute;
    top: 11px;
    right: 12px;
    cursor: pointer;
  }
}
