.account-mb-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $ss-zindex-100;
  background-color: theme('colors.brand.yellow');
  padding: 8px 0 4px;

  @media (min-width: $ss-breakpoint-md) {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    padding: 0 24px;
    text-align: center;
  }

  &__link {
    display: inline-block;
    color: theme('colors.brand.navy');

    &.active,
    &:hover {
      color: theme('colors.orange.DEFAULT');

      svg {
        color: theme('colors.orange.DEFAULT');
      }
    }
  }
}

.account-hero-img {
  @include img-as-bg;
  width: 100%;
  height: 240px;
}

.account-trip-receipt {
  border: 1px solid theme('colors.brand.green');
  border-radius: theme('borderRadius.lg');
  padding: 16px;

  &__item {
    align-items: center;
    display: flex;
    padding: 8px 16px;
  }

  &__label {
    flex-grow: 1;
    font-size: $ss-font-size-sm;
    font-weight: $ss-font-weight-bold;
    padding-right: 8px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
    }
  }

  &__total,
  &__amount {
    margin-left: auto;
    width: 130px;
    text-align: right;

    @media (min-width: $ss-breakpoint-md) {
      width: 160px;
    }
  }

  &__item--total &__amount,
  &__item--total &__label {
    font-size: $ss-font-size-lg;
    font-weight: $ss-font-weight-bold;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
    }
  }
}
