.list-horizontal-scroll {
  $component: &;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &__item {
    flex: 0 0 auto;
  }

  &__item + &__item {
    margin-left: 15px;
  }

  &__item-text {
    font-family: theme('fontFamily.styleSans');
    font-weight: theme('fontWeight.semibold');
    cursor: pointer;
    color: theme('colors.brand.navy');
    padding: 4px 0;
    display: block;
    position: relative;
    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.xl');
      padding: 8px 0;
    }

    &:before {
      content: ' ';
      border: 2px solid theme('colors.orange.DEFAULT');
      width: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      display: none;
    }

    &.active {
      &:before {
        display: block;
      }
    }
  }

  &.list-boat-icons {
    @media (min-width: $ss-breakpoint-md) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &--order {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    #{$component}__item + #{$component}__item {
      margin-left: 24px;
    }
  }

  &--order &__item-text {
    font-size: $ss-font-size-base;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-lg;
    }

    &.active {
      border-bottom: 2px solid $ss-cyan-color;
    }
  }

  &--tab {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    #{$component}__item + #{$component}__item {
      margin-left: 0;
    }
  }

  &--tab &__item-text {
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid $ss-border-color;
    color: theme('colors.brand.navy');
    font-family: theme('fontFamily.styleSans');
  }

  &--country {
    margin-bottom: -1px;
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: $ss-breakpoint-md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    #{$component}__item {
      width: 52px;
      text-align: center;

      @media (min-width: $ss-breakpoint-md) {
        width: auto;
      }
    }

    #{$component}__item + #{$component}__item {
      margin-left: 4px;

      @media (min-width: $ss-breakpoint-md) {
        margin-left: 32px;
      }
    }

    #{$component}__item-text {
      color: $ss-grey-color;
      font-size: $ss-font-size-xxs;
      width: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-sm;
        width: auto;
        overflow: visible;
        text-overflow: unset;
        white-space: normal;
      }

      &:hover {
        color: $ss-black-color;
      }

      &:focus {
        outline: none;
      }

      &.active {
        color: $ss-cyan-color;
        border: 0;
      }
    }

    #{$component}__img {
      object-fit: cover;
      width: 36px;
      height: 36px;
      display: inline-block;
      border-radius: $ss-border-radius-base;

      @media (min-width: $ss-breakpoint-md) {
        display: none;
      }
    }

    #{$component}__default-img {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: $ss-border-radius-base;
      background: $ss-cyan-color;
      text-transform: uppercase;
      color: $white;
      font-weight: $ss-font-weight-bold;
      line-height: 37px;
      text-align: center;

      @media (min-width: $ss-breakpoint-md) {
        display: none;
      }
    }
  }

  &--fixed-padding-bottom {
    margin-bottom: -16px;
  }

  &--corporate {
    #{$component}__item-text {
      &:hover {
        color: $ss-cyan-color;
        text-decoration: none;
      }

      &.active {
        color: $ss-cyan-color;
        font-weight: $ss-font-weight-bold;
        border-bottom: 0;
      }
    }
  }
}

.list-blog {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 7px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media (min-width: $ss-breakpoint-md) {
    padding: 0;
  }

  &__item {
    flex: 0 0 auto;
    width: 220px;
    height: 280px;
    padding: 0 8px;

    @media (min-width: $ss-breakpoint-md) {
      padding: 0 16px;
      width: 260px;
      height: 320px;
    }
  }

  &__item-inner {
    position: relative;
    height: 100%;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    border-radius: $ss-border-radius-base;
  }

  &__item:hover &__img {
    opacity: 0.7;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    padding: 16px 12px;
    background-color: rgba($ss-black-color, 0.4);
    left: 0;
    bottom: 0;
    border-bottom-left-radius: $ss-border-radius-base;
    border-bottom-right-radius: $ss-border-radius-base;
  }

  &__type {
    text-transform: uppercase;
    font-size: $ss-font-size-sm;
    font-weight: $ss-font-weight-bold;
    line-height: 1;
    margin-bottom: 8px;
  }

  &__title {
    font-weight: $ss-font-weight-bold;
    font-size: $ss-font-size-sm;
    color: $white;
    margin-bottom: 0;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-base;
    }
  }
}
