.title {
  font-family: theme('fontFamily.styleSans');
  font-size: 24px;
  font-weight: theme('fontWeight.semibold');
  text-align: center;
  line-height: 1;
  margin-bottom: 20px;

  @media (min-width: theme('screens.md')) {
    font-size: 28px;
  }
}

:global {
  .inline-login {
    :local {
      .title {
        text-align: center;
        font-size: 20px;
        @media (min-width: theme('screens.md')) {
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }
}
