.simple-rich-doc {
  p {
    margin-bottom: 0;
  }

  a {
    color: theme('colors.brand.navy');
    text-decoration: underline;

    &:hover {
      font-weight: theme('fontWeight.semibold');
    }
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0;

    > br {
      display: none;
    }
  }

  li {
    margin-bottom: 8px;
  }

  span {
    color: theme('colors.brand.navy') !important;
  }

  img {
    width: 100%;
    border: 2px solid theme('colors.brand.green');
    border-radius: theme('borderRadius.lg');
  }
}
