.modal-content {
  border-radius: theme('borderRadius.2xl');
  background-color: theme('colors.brand.yellow');
}

.modal-header,
.modal-footer {
  padding: 16px 24px;
  border-color: theme('colors.grey.lighter');
}

.modal-body {
  padding: 24px;
}

.modal-dialog {
  &.modal-dialog--lg {
    @media (min-width: $ss-breakpoint-sm) {
      max-width: 560px;
    }
  }

  &.modal-dialog--xl {
    @media (min-width: $ss-breakpoint-lg) {
      max-width: 992px;
    }
  }

  &.modal-full-width {
    margin: 0;
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $ss-breakpoint-lg) {
      margin: 28px;
      max-height: calc(100% - 3.5rem);
    }

    .modal-content {
      border-radius: 0;
      max-height: 100%;

      @media (min-width: $ss-breakpoint-lg) {
        border-radius: theme('borderRadius.2xl');
        max-height: calc(100% - 3.5rem);
      }
    }
  }

  &.modal-full-width--sp {
    @media (max-width: $ss-breakpoint-md - 1) {
      margin: 0;
      max-width: 100%;
      max-height: 100%;
      height: 100%;

      .modal-content {
        border-radius: 0;
        height: 100%;
      }
    }

    .modal-content {
      max-height: 100%;
    }
  }

  &.modal-dialog-bottom {
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 100%;
    height: 100%;
  }

  &.modal-almost-full-height--sp {
    @media (max-width: $ss-breakpoint-md - 1) {
      margin: 0;
      max-width: 100%;
      max-height: 80%;

      .modal-content {
        border-radius: 0;
        max-height: 80%;
      }
    }
  }
}

.modal.modal-styled {
  padding: 0 !important;
  @media (min-width: theme('screens.md')) {
    padding: 0 15px !important;
  }

  .modal-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
    height: 100%;
    margin: 0;

    @media (min-width: theme('screens.md')) {
      justify-content: center;
      max-width: 800px;
      height: calc(100vh - 56px);
      margin: 28px auto;
    }
  }

  .modal-content {
    max-height: calc(100vh - 140px);
    border-top-right-radius: theme('borderRadius.lg');
    border-top-left-radius: theme('borderRadius.lg');
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media (min-width: theme('screens.md')) {
      max-height: calc(100vh - 56px);
      border-radius: theme('borderRadius.lg');
    }
  }

  .modal-header {
    border: 0;
    padding: 16px 24px;
    border-bottom: 1px solid theme('colors.grey.lighter');
    margin-bottom: 0;

    @media (min-width: theme('screens.md')) {
      padding: 24px 40px;
    }
  }

  .modal-footer {
    padding: 16px 20px;

    @media (min-width: theme('screens.md')) {
      padding: 24px 40px;
    }
  }

  .modal-body {
    padding: 24px 20px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: theme('screens.md')) {
      padding: 32px 40px;
    }
  }

  &.modal-styled-sm {
    .modal-dialog {
      @media (min-width: theme('screens.md')) {
        max-width: 600px;
      }
    }

    .modal-content {
      max-height: 600px;
    }
  }

  &.modal-styled-center {
    .modal-dialog {
      justify-content: center;
      margin: 0 20px;

      @media (min-width: theme('screens.md')) {
        justify-content: center;
        margin: 28px auto;
      }
    }

    .modal-content {
      border-radius: theme('borderRadius.lg');

      @media (min-width: theme('screens.md')) {
        border-radius: theme('borderRadius.lg');
      }
    }

    .modal-header {
      border-bottom: 0;

      @media (min-width: theme('screens.md')) {
        border-bottom: 0;
      }
    }
  }
}

.modal-zindex-1051 {
  z-index: 1051;
}
