.corporate-event {
  .corporate-event-logo {
    padding: 48px 0;
    width: 250px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: between;

    img {
      width: 1%;
      height: auto;
      display: block;
      flex: 1 1 auto;
    }

    .close-icon {
      margin: 0 24px;
      flex-shrink: 0;
    }

    .ss-logo {
      width: 78px;
      height: 48px;
      flex-shrink: 0;
    }
  }

  .corp-title {
    font-size: $ss-font-size-title-2;
    font-weight: $ss-font-weight-bold;
    line-height: 1;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-title-5;
    }
  }

  .about-big-img {
    img {
      width: 100%;
      height: 240px;
      object-fit: cover;

      @media (min-width: $ss-breakpoint-md) {
        height: 400px;
      }
    }
  }

  .about-img {
    width: 100%;
    height: 240px;
    object-fit: cover;

    @media (min-width: $ss-breakpoint-md) {
      height: 400px;
    }
  }

  .feature-img {
    width: 100%;
    height: 200px;
    object-fit: cover;

    @media (min-width: $ss-breakpoint-md) {
      height: 320px;
    }
  }
}

.modal-about-img {
  object-fit: cover;
  width: 100%;
  height: 240px;

  @media (min-width: $ss-breakpoint-md) {
    height: 600px;
  }
}

.event-hero {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  box-shadow: 0 0 0 rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.2);

  @media (min-width: $ss-breakpoint-md) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    align-items: stretch;
  }

  .event-info {
    padding: 0 15px;

    @media (min-width: $ss-breakpoint-md) {
      width: 500px;
      padding: 60px 80px 0;
    }

    h1 {
      font-size: $ss-font-size-title-3;
      font-weight: $ss-font-weight-bold;
      margin-bottom: 8px;

      @media (min-width: $ss-breakpoint-md) {
        font-size: $ss-font-size-title-6;
        margin-bottom: 16px;
      }
    }
  }

  .event-banner {
    height: 280px;

    @media (min-width: $ss-breakpoint-md) {
      flex-grow: 1;
      height: 600px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.event-timer {
  display: flex;
  align-items: center;

  .event-timer__item {
    border-radius: $ss-border-radius-sm;
    background-color: $ss-grey-lightest-color;
    text-align: center;
    padding: 16px;
  }

  .event-timer__num {
    font-size: $ss-font-size-title-2;
    font-weight: $ss-font-weight-bold;
    line-height: 1;
    margin-bottom: 4px;
  }

  .event-timer__item + .event-timer__item {
    margin-left: 24px;
  }
}
