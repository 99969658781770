.review {
  &__header {
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      margin-bottom: 24px;
    }
  }

  &__score {
    font-size: $ss-font-size-lg;
    font-weight: $font-weight-bold;
    color: $ss-black-color;
    line-height: 1;
    margin-bottom: 10px;
    vertical-align: top;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
    }
  }

  &__body {
    padding: 15px 0;
    margin: 15px 0;
    border-bottom: 1px solid $ss-border-color;
  }

  &__item + &__item {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid theme('colors.grey.lighter');

    @media (min-width: $ss-breakpoint-md) {
      margin-top: 32px;
      padding-top: 0;
      border-top: 0;
    }
  }

  &__item--google {
    border-top: 1px solid $ss-border-color;
    padding-top: 20px;

    &:first-child {
      padding-top: 24px;
    }

    @media (min-width: $ss-breakpoint-md) {
      padding-top: 30px;

      &:first-child {
        padding-top: 48px;
      }
    }
  }

  &__item + &__item--google {
    margin-top: 20px;

    @media (min-width: $ss-breakpoint-md) {
      margin-top: 30px;
    }
  }

  &__item-date {
    color: $ss-grey-color;
  }

  &__item-header {
    margin-bottom: 10px;
  }

  &__item-reviewer {
    font-weight: $font-weight-bold;
    margin-right: 10px;
    display: inline-block;
  }

  &__item-star {
    display: inline-block;
  }

  &__item-feedback {
    margin-top: 8px;
  }

  &__item-option {
    margin-bottom: 8px;
    color: $ss-grey-color;
    font-weight: $ss-font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__item-content {
    p {
      margin-bottom: 8px;
    }
  }
}

.review-star {
  fill: theme('colors.white');
  color: theme('colors.orange.DEFAULT');
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &.active {
    fill: theme('colors.orange.DEFAULT');
  }
}
.review-star-no-hover {
  fill: theme('colors.white');
  color: theme('colors.orange.DEFAULT');
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    fill: theme('colors.orange.DEFAULT');
  }
}

.review-like-reason {
  padding: 8px;
  border: 1px solid $ss-border-color;
  border-radius: $border-radius;
  text-align: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: $ss-font-size-xs;

  @media (min-width: 375px) {
    font-size: $ss-font-size-sm;
  }

  @media (min-width: $ss-breakpoint-md) {
    font-size: $ss-font-size-base;
  }

  &:hover {
    background-color: $ss-grey-lightest-color;
  }

  &.selected {
    color: $white;
    font-weight: $ss-font-weight-bold;
    background-color: $ss-cyan-color;
    border-color: $ss-cyan-color;
  }
}

.review-board {
  border: 1px solid $ss-border-color;
  padding: 24px;
  align-items: center;
  display: block;
  background-color: theme('colors.white');
  border-radius: theme('borderRadius.lg');

  @media (min-width: $ss-breakpoint-md) {
    display: flex;
  }

  &__score {
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      margin: 0;
      margin-right: 32px;
    }
  }

  &__score-badge {
    text-align: center;
    padding: 12px 8px;
    background-color: theme('colors.brand.navy');
    margin-bottom: 8px;
  }

  &__score-cap {
    color: theme('colors.brand.green');
  }

  &__score-average {
    font-size: $ss-font-size-title-3;
    color: theme('colors.brand.green');
    font-weight: $ss-font-weight-bold;
    line-height: 1;
  }

  &__chart {
    flex-grow: 1;
  }

  &__chart-item {
    display: flex;
    align-items: center;
  }

  &__chart-item + &__chart-item {
    margin-top: 12px;
  }

  &__chart-label {
    width: 90px;
    font-weight: $ss-font-weight-bold;
    color: $ss-grey-light-color;
    line-height: 1;
  }

  &__chart-num {
    width: 40px;
    line-height: 1;
    text-align: right;
    font-weight: $ss-font-weight-bold;
    color: theme('colors.brand.navy');
  }

  &__chart-line {
    flex-grow: 1;
    height: 10px;
    background-color: $ss-grey-lightest-color;
    border-radius: 16px;
    position: relative;
  }

  &__chart-filled-line {
    background-color: $ss-yellow-color;
    border-radius: 16px;
    height: 10px;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}
