.img-grid {
  position: relative;
  cursor: pointer;
  height: 280px;

  @media (min-width: $ss-breakpoint-md) {
    height: 380px;
  }

  &__item {
    position: relative;
    height: 280px;
    width: 100%;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-top: 1px solid $white;

    @include img-as-bg;

    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: $ss-breakpoint-md) {
      height: 380px;
    }

    &--sm {
      height: 190px;
    }
  }

  &__row {
    overflow: hidden;

    .col-md-6 {
      overflow: hidden;
    }

    .col-md-6 + .col-md-6 {
      border-left: 1px solid white;
    }
  }

  &__btn-carousel {
    position: absolute;
    bottom: 16px;
    right: 32px;
  }
}
