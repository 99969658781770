.hide {
  display: none;
}

.row.no-gutter {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.img-as-bg {
  @include img-as-bg();
}

.img-profile {
	width: 60px;
	height: 60px;
	border: 1px inset rgba(0, 0, 0, .1);
  border-radius: 50%;

  &.img-profile--sm { width: 24px;
    height: 24px;
  }
}

.img-profile-letter {
  display: inline-block;
	background-color: $ss-blue-dark-color;
	color: $white;
	text-align: center;
	text-transform: uppercase;
	line-height: 57px;
	font-size: $ss-font-size-xl;
  @extend .img-profile;

  &--black {
    background-color: $ss-black-color;
    border-color: $ss-grey-light-color;
    color: $white;
  }

  &.img-profile-letter--sm {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 22px;
  }
}

.option-quick-view {
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: $ss-zindex-20;
  width: 52px;
  height: 52px;
  padding-top: 12px;
  padding-left: 12px;
  text-align: center;
  cursor: pointer;

  &__btn {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    background: theme('colors.purple.DEFAULT');
  }

  svg {
    color: theme('colors.brand.navy');
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }
}

.gm-style img {
  background-color: transparent;
}

.aligned-addon-price {
  padding-left: 30px;
  margin-top: 8px;

  @media (min-width: $ss-breakpoint-md) {
    margin-top: 0;
    padding-left: 0;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.u-bg-facebook {
  background-color: $facebook-color;
}

.u-bg-google {
  background-color: #E22809;
}
