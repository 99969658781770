.activity-faq {
  margin-top: 32px;

  &__content {
    display: flex;
    align-items: top;
  }

  &__content + &__content {
    margin-top: 12px;
  }

  &__item + &__item {
    margin-top: 32px;
  }

  &__content-icon {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-weight: $ss-font-weight-bold;
    margin-right: 32px;
    color: $white;

    display: none;

    @media (min-width: $ss-breakpoint-md) {
      display: block;
    }

    &--question {
      background-color: $ss-cyan-color;
    }

    &--answer {
      background-color: $ss-grey-color;
    }
  }

  &__content-body {
    flex-grow: 1;
    align-self: center;
  }

  &__content-meta {
    color: $ss-grey-light-color;
    font-size: $ss-font-size-sm;
  }

  &__content-title {
    &--big {
      font-weight: $ss-font-weight-bold;
      font-size: $ss-font-size-xl;
    }
  }
}
