.media {
  .media-trip-img {
    @include img-as-bg;
    width: 120px;
    min-height: 100%;
    margin-right: 8px;

    @media (min-width: $ss-breakpoint-md) {
      width: 300px;
      margin-right: 16px;
    }
  }

  .media-boat {
    margin-right: 10px;

    @media (min-width: $ss-breakpoint-md) {
      align-self: center;
      margin-right: 20px;
    }
  }
}
