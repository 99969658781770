@font-face {
    font-family: 'Kobe';
    src: url('/fonts/Kobe11-BlackOblique.eot');
    src: url('/fonts/Kobe11-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-BlackOblique.woff2') format('woff2'),
        url('/fonts/Kobe11-BlackOblique.woff') format('woff'),
        url('/fonts/Kobe11-BlackOblique.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kobe';
    src: url('/fonts/Kobe11-Regular.eot');
    src: url('/fonts/Kobe11-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-Regular.woff2') format('woff2'),
        url('/fonts/Kobe11-Regular.woff') format('woff'),
        url('/fonts/Kobe11-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kobe';
    src: url('/fonts/Kobe11-Oblique.eot');
    src: url('/fonts/Kobe11-Oblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-Oblique.woff2') format('woff2'),
        url('/fonts/Kobe11-Oblique.woff') format('woff'),
        url('/fonts/Kobe11-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kobe Bold Oblique';
    src: url('/fonts/Kobe11-BoldOblique.eot');
    src: url('/fonts/Kobe11-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-BoldOblique.woff2') format('woff2'),
        url('/fonts/Kobe11-BoldOblique.woff') format('woff'),
        url('/fonts/Kobe11-BoldOblique.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kobe';
    src: url('/fonts/Kobe11-Bold.eot');
    src: url('/fonts/Kobe11-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-Bold.woff2') format('woff2'),
        url('/fonts/Kobe11-Bold.woff') format('woff'),
        url('/fonts/Kobe11-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kobe';
    src: url('/fonts/Kobe11-Black.eot');
    src: url('/fonts/Kobe11-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kobe11-Black.woff2') format('woff2'),
        url('/fonts/Kobe11-Black.woff') format('woff'),
        url('/fonts/Kobe11-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Thin.eot');
    src: url('/fonts/Outfit-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Thin.woff2') format('woff2'),
        url('/fonts/Outfit-Thin.woff') format('woff'),
        url('/fonts/Outfit-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-SemiBold.eot');
    src: url('/fonts/Outfit-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-SemiBold.woff2') format('woff2'),
        url('/fonts/Outfit-SemiBold.woff') format('woff'),
        url('/fonts/Outfit-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Black.eot');
    src: url('/fonts/Outfit-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Black.woff2') format('woff2'),
        url('/fonts/Outfit-Black.woff') format('woff'),
        url('/fonts/Outfit-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-ExtraLight.eot');
    src: url('/fonts/Outfit-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-ExtraLight.woff2') format('woff2'),
        url('/fonts/Outfit-ExtraLight.woff') format('woff'),
        url('/fonts/Outfit-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Light.eot');
    src: url('/fonts/Outfit-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Light.woff2') format('woff2'),
        url('/fonts/Outfit-Light.woff') format('woff'),
        url('/fonts/Outfit-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-ExtraBold.eot');
    src: url('/fonts/Outfit-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-ExtraBold.woff2') format('woff2'),
        url('/fonts/Outfit-ExtraBold.woff') format('woff'),
        url('/fonts/Outfit-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Regular.eot');
    src: url('/fonts/Outfit-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Regular.woff2') format('woff2'),
        url('/fonts/Outfit-Regular.woff') format('woff'),
        url('/fonts/Outfit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Bold.eot');
    src: url('/fonts/Outfit-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Bold.woff2') format('woff2'),
        url('/fonts/Outfit-Bold.woff') format('woff'),
        url('/fonts/Outfit-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('/fonts/Outfit-Medium.eot');
    src: url('/fonts/Outfit-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Outfit-Medium.woff2') format('woff2'),
        url('/fonts/Outfit-Medium.woff') format('woff'),
        url('/fonts/Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
