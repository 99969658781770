.pagination {
  align-items: center;

  &__item {
    display: inline-block;
    color: theme('colors.white');
    font-size: $ss-font-size-base;
    line-height: 1;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;

    i {
      font-size: $ss-font-size-xl;
    }

    &.current {
      background-color: theme('colors.brand.navy');
      color: theme('colors.white');
      border-radius: 50%;
    }
    &.gap {
      color: theme('colors.brand.navy');
    }
    &.prev,
    &.next {
      border: 1px solid theme('colors.brand.navy');
      border-radius: 50%;
      color: theme('colors.brand.navy');
      svg {
        height: 100%;
        vertical-align: top;
      }
    }
  }

  &__item + &__item {
    margin-left: 15px;
  }

  &--big-font-size {
    .pagination__item {
      font-size: $ss-font-size-xl;
    }
  }
}
