.hero {
  @include img-as-bg;
  height: 500px;
  width: 100%;
  position: relative;
  background-position: center center;

  &--creator {
    background-image: url('/images/creator_community/hero.jpg');
    height: 414px;

    @media(min-width: $ss-breakpoint-md) {
      height: 640px;
    }
  }

  &--tried-and-tested {
    background-image: url('/images/tried_and_tested/hero.jpg');

    @media (min-width: $ss-breakpoint-md) {
      background-position: center 30%;
    }

    @include hero-overlay();
  }

  &--tried-and-tested-2 {
    background-image: url('/images/tried_and_tested/hero_2.png');
  }

  &--our-quality {
    background-image: url('/images/our_quality/hero.jpg');
    @include hero-overlay();
  }

  &__inner {
    width: 100%;
    position: absolute;
    z-index: $ss-zindex-10;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__inner--home {
    margin-top: 65px;
    @media (min-width: $ss-breakpoint-md) {
      margin-top: 0;
    }
  }

  &__overlay {
    width: 100%;
    height: 290px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(1deg, rgba($body-color, 0.5) 40%, rgba(white, 0) 100%);

    @media (min-width: $ss-breakpoint-md) {
      display: none;
    }
  }

  &__title {
    font-size: $ss-font-size-title-4;
    font-weight: $ss-font-weight-bold;
    color: $white;

    @media (min-width: $ss-breakpoint-md) {
      font-size: 50px;
    }
  }

  &__tagline {
    color: $white;
    font-size: $ss-font-size-base;
    margin-bottom: 16px;

    @media (min-width: $ss-breakpoint-md) {
      font-size: $ss-font-size-xl;
      margin-bottom: 32px;
    }
  }
}

.img-about-big {
  object-fit: cover;
  height: 400px;
  width: 100%;
}

.img-about-small {
  object-fit: cover;
  height: 300px;
  width: 100%;
}

.video-about {
  position: relative;
  padding-bottom: 75%;
  height: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.container-about-video-float {
  margin-top: -150px;
  margin-bottom: 40px;

  @media (min-width: $ss-breakpoint-md) {
    margin-bottom: 80px;
  }

  .video-about {
    padding-bottom: 100%;
  }

  .img-about-big {
    height: 170px;

    @media (min-width: $ss-breakpoint-md) {
      height: 445px;
    }
  }
}

.creator-video-top {
  @media (min-width: $ss-breakpoint-md) {
    padding-top: 328px;
  }
}

.creator-video {
  width: 100%;
  margin-top: -48px;
  margin-bottom: 48px;

  @media (min-width: $ss-breakpoint-md) {
    width: 352px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -376px;
    margin-bottom: 80px;
  }
}

.creator-banner {
  width: 100%;
  background: $ss-cyan-color;
  color: $white;
  font-weight: $ss-font-weight-bold;
  line-height: 1;
  padding: 8px 0;
}

.carousel-creator-video {
  .slick-arrow {
    top: auto;
    bottom: -60px;
  }

  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    line-height: 32px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.slick-disabled {
      display: inline-block !important;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  .slick-prev {
    left: auto;
    right: 44px;
  }

  .slick-next {
    right: 0;
  }
}

.carousel-item-creator-video {
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.5;
  }

  &.remove-overlay {
    &:after {
      content: none;
    }
  }

  iframe {
    border-radius: 8px;

    @media (max-width: $ss-breakpoint-md - 1) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.creator-video-container {
  position: relative;
  padding: 177.5% 0 0 0;

  @media (min-width: $ss-breakpoint-md) {
    padding: 0;
  }
}
